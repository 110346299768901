import women from "../assets/women.webp";
import businessman from "../assets/businessman.webp";
import consumer from "../assets/consumer.webp";
import investor from "../assets/investor.webp";
import employee from "../assets/employee.webp";
import senior_citizen from "../assets/senior_citizen.webp";

const data = {
  woman: {
    heading: "Women",
    banner: women,
    sections: [
      {
        id: "women-s1",
        heading: "Women's Rights in India",
        content: [
          {
            id: "women-s1-p1",
            para: "India is a vast and diverse country with a rich history and culture. Unfortunately, women in India have historically been treated as second-class citizens. They have been denied basic rights such as education, property ownership, and the right to vote. In recent years, there has been progress in terms of women's rights in India, but there is still much work to be done.",
          },
        ],
      },
      {
        id: "women-s2",
        heading: "Constitutional Rights",
        content: [
          {
            id: "women-s2-p1",
            para: "The Constitution of India guarantees certain fundamental rights to all citizens, including women. These rights include the right to equality, the right to freedom of speech and expression, the right to life and liberty, and the right to property. However, these rights are not always upheld in practice. For example, women in India are still denied equal opportunities in education and employment. They are also more likely to be victims of violence than men.",
          },
        ],
      },
      {
        id: "women-s3",
        heading: "Legislative Protection",
        content: [
          {
            id: "women-s3-p1",
            para: "In addition to the Constitution, there are a number of laws in India that protect the rights of women. These laws include the Dowry Prohibition Act, the Immoral Traffic (Prevention) Act, the Protection of Women from Domestic Violence Act, and the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act. These laws have helped to improve the lives of women in India, but they still have a long way to go.",
          },
        ],
      },
      {
        id: "women-s4",
        heading: "Issues",
        content: [
          {
            id: "women-s4-p1",
            para: "There are a number of issues that continue to challenge women's rights in India. ",
          },
          {
            para: "These include:",
          },

          {
            id: "women-s4-p2",
            para: " • Gender discrimination: Women in India continue to face discrimination in all aspects of their lives. They are less likely to be educated than men, and they are more likely to be poor. They are also more likely to be victims of violence, both in the home and in the workplace.",
          },
          {
            id: "women-s4-p3",
            para: " • Violence against women: Violence against women is a serious problem in India. Every year, thousands of women are killed in dowry-related violence, and millions more are subjected to physical and sexual abuse.",
          },
          {
            id: "women-s4-p4",
            para: " • Unequal pay: Women in India are still paid less than men for doing the same work. This is due to a number of factors, including gender discrimination and the lack of opportunities for women in the workforce.",
          },
          {
            id: "women-s4-p5",
            para: " • Access to education: Girls in India are still less likely to be educated than boys. This is due to a number of factors, including poverty, lack of access to schools, and cultural norms that discourage girls from going to school.",
          },
        ],
      },
      {
        id: "women-s5",
        heading: "Progress",
        content: [
          {
            id: "women-s5-p1",
            para: "Despite the challenges, there has been some progress in terms of women's rights in India in recent years. For example, the number of girls enrolled in school has increased significantly, and the number of women in the workforce has also increased. In addition, the government has passed a number of laws that protect the rights of women.",
          },
          {
            id: "women-s5-p2",
            para: "Statutes for the protection of rights of women in India:",
          },
          {
            id: "women-s5-p3",
            para: " • Constitution of India: The Constitution of India guarantees equal rights to all citizens, regardless of gender. Article 14 prohibits discrimination on the basis of sex, and Article 15(3) allows the state to make special provisions for women and children.",
          },
          {
            id: "women-s5-p4",
            para: " • Immoral Traffic (Prevention) Act, 1956: This Act prohibits the trafficking of women and girls for the purpose of prostitution.",
          },
          {
            id: "women-s5-p5",
            para: " • Dowry Prohibition Act, 1961: This Act prohibits the giving or taking of dowry, and provides for punishment for those who violate the law.",
          },
          {
            id: "women-s5-p6",
            para: " • Commission of Sati (Prevention) Act, 1987: This Act prohibits the practice of sati, which is the self-immolation of a widow on her husband's funeral pyre.",
          },
          {
            id: "women-s5-p7",
            para: " • Protection of Women from Domestic Violence Act, 2005: This Act provides for protection of women from domestic violence, including physical, emotional, and economic abuse.",
          },
          {
            id: "women-s5-p8",
            para: " • Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013: This Act prohibits sexual harassment of women at the workplace, and provides for a mechanism for redressal of complaints.",
          },
          {
            id: "women-s5-p9",
            para: " • Criminal Law (Amendment) Act, 2013: This Act amended the Indian Penal Code to increase the punishment for crimes against women, such as rape, acid attack, and trafficking.",
          },
          {
            id: "women-s5-p10",
            para: " • Indecent Representation of Women (Prohibition) Act, 1986: This Act prohibits the indecent representation of women in any form of communication, including print, visual, and electronic media.",
          },
          {
            id: "women-s5-p11",
            para: " • These are just some of the statutes that protect the rights of women in India. There are many other laws and regulations that also contribute to the promotion of gender equality in the country.",
          },
        ],
      },
      {
        id: "women-s6",
        heading: "Way Forward",
        content: [
          {
            id: "women-s6-p1",
            para: "There is still much work to be done to ensure that all women in India have equal rights. The government needs to take steps to address the issues of gender discrimination, violence against women, and unequal pay. In addition, there needs to be a change in the cultural attitudes towards women. Women need to be seen as equal partners in society, and they need to be given the same opportunities as men.",
          },
        ],
      },
      {
        id: "women-s7",
        heading: "Conclusion",
        content: [
          {
            id: "women-s7-p1",
            para: "The progress that has been made in terms of women's rights in India is encouraging. However, there is still much work to be done. The government and society as a whole need to continue to work towards ensuring that all women in India have equal rights.",
          },
        ],
      },
    ],
  },
  businessman: {
    heading: "Businessmen",
    banner: businessman,
    sections: [
      {
        id: "businessman-s1",
        heading: "Businessmen's Rights in India",
        content: [
          {
            id: "businessman-s1-p1",
            para: "The rights of a businessman in India are protected by a number of laws and regulations, including the Constitution of India, the Contract Act, 1872, the Companies Act, 2013, and the Consumer Protection Act, 2019.",
          },
          {
            "para ":
              "Some of the key rights of a businessman in India include:",
          },
          {
            para: " • The right to freedom of trade and commerce: This right is guaranteed by Article 19(1)(g) of the Constitution of India. It allows businesses to freely choose their trade or occupation, and to carry on their business without any interference from the government.",
          },
          {
            para: " • The right to enter into contracts: This right is guaranteed by the Contract Act, 1872. It allows businesses to enter into contracts with other businesses or individuals, and to enforce those contracts in court.",
          },
          {
            para: " • The right to own property: This right is guaranteed by Article 300A of the Constitution of India. It allows businesses to own property, and to use that property for their business purposes.",
          },
          {
            para: " • The right to protection from unfair competition: This right is guaranteed by the Competition Act, 2002. It prevents businesses from engaging in unfair trade practices that would harm their competitors.",
          },
          {
            para: " • The right to protection from consumer fraud: This right is guaranteed by the Consumer Protection Act, 2019. It prevents businesses from engaging in practices that would deceive or mislead consumers.",
          },
          {
            para: "In addition to these specific rights, businesses also have a number of general rights, such as the right to due process of law, the right to privacy, and the right to freedom of speech.",
          },
          {
            para: "Businesses that are aware of their rights and are able to enforce those rights are more likely to be successful. If you are a businessman in India, it is important to understand your rights and to take steps to protect them.",
          },
          {
            para: "Here are some tips for businesses to protect their rights:",
          },
          {
            para: " • Be aware of your rights: It is important to be aware of the laws and regulations that protect your rights as a businessman.",
          },
          {
            para: " • Keep good records: Keep all records related to your business, such as contracts, invoices, and receipts. This will help you to enforce your rights if they are violated.",
          },
          {
            para: " • Consult with an attorney: If you have any questions about your rights, or if you believe that your rights have been violated, you should consult with an attorney.",
          },
          {
            para: "By following these tips, businesses can help to protect their rights and ensure that they are treated fairly.",
          },
        ],
      },
    ],
  },
  consumer: {
    heading: "Consumers",
    banner: consumer,
    sections: [
      {
        id: "consumer-s1",
        heading: "Protection of consumer rights in India:",
        content: [
          {
            id: "",
            para: "The protection of consumer rights in India is governed by the Consumer Protection Act, 2019. This Act was enacted to provide for better protection of the interests of consumers and for that purpose to make provision for the establishment of consumer councils and other authorities for the settlement of consumers' disputes and for matters connected therewith.",
          },
          {
            para: 'The Act defines a consumer as "any person who buys any goods for a consideration which has been paid or promised or partly paid and partly promised, or under any system of deferred payment and includes any user of such goods with the approval of the person who bought such goods".',
          },
        ],
      },
      {
        id: "consumer-s2",
        heading: "The Act also defines six consumer rights, namely:",
        content: [
          {
            para: " • The right to safety: This means the right to be protected against the marketing of goods and services which are hazardous to life and property.",
          },
          {
            para: " • The right to be informed: This means the right to be informed about the quality, quantity, potency, purity, standard and price of goods so as to protect the consumer against unfair trade practices.",
          },
          {
            para: " • The right to choose: This means the right to be assured, wherever possible of access to variety of goods and services at competitive prices.",
          },
          {
            para: " • The right to be heard: This means that consumer's interests will receive due consideration at appropriate forums.",
          },
          {
            para: " • The right to seek redressal: This means the right to get relief against unfair trade practices or unscrupulous exploitation of consumers.",
          },
          {
            para: " • The right to consumer education: This means the right to acquire knowledge and skills to become an informed consumer.",
          },
        ],
      },
      {
        id: "consumer-s3",
        heading:
          "The Act establishes a three-tier consumer protection machinery, consisting of:",
        content: [
          {
            para: " • District Consumer Disputes Redressal Commission (DDRC): This is the first level of redressal. It has jurisdiction to entertain complaints where the value of goods or services does not exceed Rs. 1 crore.",
          },
          {
            para: " • State Consumer Disputes Redressal Commission (SCDRC): This is the second level of redressal. It has jurisdiction to entertain complaints where the value of goods or services exceeds Rs. 1 crore but does not exceed Rs. 10 crores.",
          },
          {
            para: " • National Consumer Disputes Redressal Commission (NCDRC): This is the highest level of redressal. It has jurisdiction to entertain complaints where the value of goods or services exceeds Rs. 10 crores.",
          },
        ],
      },
      {
        id: "",
        heading:
          "The Act also provides for a number of other measures for the protection of consumer rights, such as:",
        content: [
          {
            para: " • The establishment of consumer councils: These councils are responsible for promoting and protecting consumer rights.",
          },
          {
            para: " • The appointment of consumer protection officers: These officers are responsible for enforcing the provisions of the Act.",
          },
          {
            para: " • The publication of consumer awareness materials: These materials are designed to educate consumers about their rights and responsibilities.",
          },
          {
            para: "The Consumer Protection Act, 2019 is a comprehensive piece of legislation that provides a strong framework for the protection of consumer rights in India. The Act has been effective in helping consumers to get their grievances redressed and in promoting consumer awareness.",
          },
        ],
      },
      {
        id: "",
        heading: "Here are some tips for consumers to protect their rights:",
        content: [
          {
            para: " • Be aware of your rights: It is important to be aware of the six consumer rights that are enshrined in the Consumer Protection Act, 2019.",
          },
          {
            para: " • Be careful when making purchases: Do your research before making a purchase and make sure that you are buying from a reputable retailer.",
          },
          {
            para: " • Keep records: Keep all receipts and other documentation related to your purchase in case you need to file a complaint.",
          },
          {
            para: " • File a complaint: If you have a complaint, do not hesitate to file a complaint with the appropriate consumer forum.",
          },
          {
            para: "By following these tips, consumers can help to protect their rights and ensure that they get fair treatment when they make purchases.",
          },
        ],
      },
    ],
  },
  "invester-in-india": {
    heading: "Investors",
    banner: investor,
    sections: [
      {
        id: "investors-s1",
        heading: "Introduction",
        content: [
          {
            para: "Investing is a way to grow your wealth over time. However, it is important to be aware of your rights as an investor, so that you can protect your investment and make informed decisions.",
          },
          {
            para: "In India, there are a number of laws that protect the rights of investors. These laws are designed to ensure that investors have access to information about the companies they invest in, that they have a voice in the management of the companies, and that they are protected from fraud.",
          },
        ],
      },
      {
        id: "investors-s2",
        heading: "Right to Information",
        content: [
          {
            para: "One of the most important rights of an investor is the right to information. This means that you have the right to receive information about the companies you invest in, such as their financial statements, management reports, and any other information that is material to the investment decision.",
          },
          {
            para: "The Securities and Exchange Board of India (SEBI) is the regulator of the securities market in India. SEBI has the power to require companies to disclose information to investors. This information is made available to investors through the SEBI website and through other channels.",
          },
        ],
      },
      {
        id: "investors-s3",
        heading: "Right to Vote",
        content: [
          {
            para: "As an investor, you also have the right to vote on important matters affecting the company, such as the election of directors and the approval of major transactions. This right allows you to have a say in how the company is managed and how your investment is used.",
          },
        ],
      },
      {
        id: "investors-s4",
        heading: "Right to Receive Dividends",
        content: [
          {
            para: "If you invest in a company that makes a profit, you have the right to receive dividends. Dividends are a share of the company's profits, and they are paid to shareholders on a regular basis.",
          },
        ],
      },
      {
        id: "investors-s5",
        heading: "Right to Sell Your Shares",
        content: [
          {
            para: "You also have the right to sell your shares in a company at any time. This right is important, as it allows you to exit your investment if you no longer wish to hold it.",
          },
        ],
      },
      {
        id: "investors-s6",
        heading: "Right to Be Protected from Fraud",
        content: [
          {
            para: "Finally, you have the right to be protected from fraud. This means that companies cannot mislead investors about their financial performance or their prospects. If you believe that you have been the victim of fraud, you can file a complaint with SEBI.",
          },
        ],
      },
      {
        id: "investors-s7",
        heading: "Laws that Protect the Rights of Investors",
        content: [
          {
            para: "The rights of investors in India are protected by a number of laws. These laws include:",
          },
          {
            para: " • The Securities and Exchange Board of India Act, 1992",
          },
          {
            para: " • The Companies Act, 2013",
          },
          {
            para: " • The Prevention of Money Laundering Act, 2002",
          },
          {
            para: " • The Investor Education and Protection Fund Act, 2002",
          },
        ],
      },
      {
        id: "investors-s8",
        heading: "Conclusion",
        content: [
          {
            para: "The rights of investors in India are extensive. By being aware of your rights, you can protect your investment and make informed decisions. If you believe that your rights have been violated, you should seek legal advice.",
          },
          {
            para: "In addition to the rights listed above, investors in India are also entitled to the following:",
          },
          {
            para: " • The right to a fair and transparent market.",
          },
          {
            para: " • The right to be compensated for losses suffered due to fraud.",
          },
          {
            para: " • The right to access the courts to seek redressal for any violation of their rights.",
          },
          {
            para: "If you are an investor in India, it is important to be aware of your rights. You should also be aware of the laws that protect your rights. If you believe that your rights have been violated, you should seek legal advice.",
          },
          {
            para: "Here are some tips for investors to protect their rights:",
          },
          {
            para: " • Do your research before you invest. Make sure you understand the company you are investing in and the risks involved.",
          },
          {
            para: " • Read the company's financial statements and management reports. This will give you information about the company's financial performance and its future prospects.",
          },
          {
            para: " • Ask questions. If you have any questions about the company or the investment, don't be afraid to ask.",
          },
          {
            para: " • Stay informed. Keep track of the company's performance and any changes in its business.",
          },
          {
            para: " • Be patient. Investing is a long-term game. Don't expect to get rich quick.",
          },
          {
            para: "By following these tips, you can help to protect your rights as an investor and make informed decisions about your investments.",
          },
        ],
      },
    ],
  },
  "employee-in-india": {
    heading: "Employees",
    banner: employee,
    sections: [
      {
        id: "employess-s1",
        heading: "Introduction",
        content: [
          {
            para: "India is a vast and diverse country, with a population of over 1.3 billion people. As a result, there is a wide range of employee rights in India, depending on the state or union territory in which an employee works. However, there are some basic rights that all employees in India are entitled to, regardless of their location.",
          },
        ],
      },
      {
        id: "employess-s2",
        heading: "Right to Fair Wages",
        content: [
          {
            para: "One of the most important rights of an employee in India is the right to fair wages. This means that employees are entitled to a wage that is enough to meet their basic needs and provide a decent standard of living for themselves and their families. The Minimum Wages Act, 1948, sets out the minimum wages that must be paid to employees in various sectors of the economy. However, it is important to note that the minimum wages set by the Act are often not enough to meet the basic needs of employees, and many employees are forced to work for less than the minimum wage.",
          },
        ],
      },
      {
        id: "employess-s3",
        heading: "Right to Leave and Holidays",
        content: [
          {
            para: "Employees in India are also entitled to a number of leaves and holidays, including sick leave, annual leave, and maternity leave. The amount of leave and holidays that an employee is entitled to depends on their length of service and the type of employment contract they have. For example, employees who are on a permanent contract are usually entitled to more leave and holidays than employees who are on a temporary contract.",
          },
        ],
      },
      {
        id: "employess-s4",
        heading: "Right to Safety and Health",
        content: [
          {
            para: "Employees in India are also entitled to a safe and healthy work environment. This means that employers have a duty to take steps to protect the health and safety of their employees. This includes providing adequate safety equipment, training employees on safety procedures, and investigating accidents and incidents.",
          },
        ],
      },
      {
        id: "employess-s5",
        heading: "Right to Unionize",
        content: [
          {
            para: "Employees in India have the right to form and join trade unions. Trade unions are organizations that represent the interests of employees in the workplace. They can negotiate with employers on behalf of employees, and they can also take industrial action, such as strikes or lockouts, if necessary.",
          },
        ],
      },
      {
        id: "employess-s6",
        heading: "Right to Equal Treatment",
        content: [
          {
            para: "Employees in India are entitled to equal treatment in the workplace. This means that they should not be discriminated against on the basis of their race, religion, caste, sex, or any other protected characteristic. Employers have a duty to create a workplace that is free from discrimination.",
          },
        ],
      },
      {
        id: "employess-s7",
        heading: "Right to Grievance Redressal",
        content: [
          {
            para: "If an employee feels that their rights have been violated, they have the right to file a grievance. Grievances can be filed with the employer, a trade union, or a government agency. The grievance will be investigated, and if the employee's complaint is upheld, the employer will be required to take corrective action.",
          },
        ],
      },
      {
        id: "employess-s8",
        heading: "Statutes for protecting the rights of an employee in India:",
        content: [
          {
            para: " • The Constitution of India: The Constitution of India guarantees certain fundamental rights to all citizens, including the right to equality, the right to freedom, and the right to life and liberty. These rights also apply to employees, and employers are prohibited from discriminating against employees on the basis of their race, religion, caste, sex, or any other protected characteristic.",
          },
          {
            para: " • The Industrial Disputes Act, 1947: This Act regulates industrial relations in India. It provides for the settlement of industrial disputes through conciliation, arbitration, and adjudication. The Act also protects the rights of employees during an industrial dispute, such as the right to strike and the right to be reinstated if they are unfairly dismissed.",
          },
          {
            para: " • The Factories Act, 1948: This Act regulates the working conditions in factories in India. It sets out minimum standards for health, safety, and welfare of employees working in factories. The Act also prohibits the employment of children in factories.",
          },
          {
            para: " • The Minimum Wages Act, 1948: This Act sets out the minimum wages that must be paid to employees in various sectors of the economy. The minimum wages are determined by the government, and they are reviewed periodically.",
          },
          {
            para: " • The Payment of Wages Act, 1936: This Act regulates the payment of wages to employees in India. It requires employers to pay wages on time and in full, and it prohibits the deduction of wages without the employee's consent.",
          },
          {
            para: " • The Payment of Bonus Act, 1965: This Act provides for the payment of bonus to employees in certain industries. The bonus is calculated as a percentage of the employee's salary, and it is payable to employees who have completed at least one year of service.",
          },
          {
            para: " • The Employees' Provident Funds and Miscellaneous Provisions Act, 1952: This Act provides for the establishment of provident funds for employees in India. The provident fund is a savings scheme, and it is used to provide retirement benefits to employees.",
          },
          {
            para: " • The Employee's State Insurance Act, 1948: This Act provides for the establishment of state insurance schemes for employees in India. The state insurance schemes provide benefits such as sickness benefits, maternity benefits, and disability benefits.",
          },
          {
            para: " • The Maternity Benefit Act, 1961: This Act provides for the protection of the rights of women employees during and after pregnancy. The Act requires employers to provide maternity leave to women employees, and it also prohibits the dismissal of women employees during pregnancy and for a period of six months after the birth of their child.",
          },
          {
            para: " • The Equal Remuneration Act, 1976: This Act prohibits discrimination between men and women employees with regard to remuneration. The Act requires employers to pay equal remuneration to men and women employees for work of equal value.",
          },
          {
            para: " • The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013: This Act prohibits sexual harassment of women at the workplace. The Act provides for the establishment of Internal Complaints Committees (ICCs) in all workplaces to address complaints of sexual harassment.",
          },
          {
            para: "These are just some of the statutes that protect the rights of employees in India. It is important for employees to be aware of their rights, and to be able to assert them if necessary.",
          },
          {
            para: "In addition to the rights listed above, employees in India are also entitled to the following:",
          },
          {
            para: " • The right to a written employment contract",
          },
          {
            para: " • The right to be paid on time and in full",
          },
          {
            para: " • The right to be protected from sexual harassment",
          },
          {
            para: " • The right to privacy",
          },
          {
            para: " • The right to participate in decision-making",
          },
          {
            para: "If you are an employee in India, it is important to be aware of your rights. You should also be aware of the laws that protect your rights. If you believe that your rights have been violated, you should seek legal advice.",
          },
        ],
      },
    ],
  },
  "senior-citizen": {
    heading: "Senior Citizens",
    banner: senior_citizen,
    sections: [
      {
        id: "senior-s1",
        heading: "Rights of senior citizens in India – ",
        content: [
          {
            para: "India has a large population of senior citizens, and the government has taken steps to protect their rights. The Maintenance and Welfare of Parents and Senior Citizens Act, 2007, is one of the most important laws that protects the rights of senior citizens in India.",
          },
          {
            para: "This Act provides for the maintenance and welfare of senior citizens, and it also sets out the legal rights of senior citizens. Some of the most important legal rights of senior citizens in India include:",
          },
          {
            para: " • The right to maintenance from their children or other relatives",
          },
          {
            para: " • The right to live in a safe and secure environment",
          },
          {
            para: " • The right to be free from abuse and neglect",
          },
          {
            para: " • The right to access healthcare and other essential services",
          },
          {
            para: " • The right to participate in social and cultural activities",
          },
          {
            para: " • The right to make their own decisions about their lives",
          },
        ],
      },
      {
        id: "senior-s2",
        heading: "Right to Maintenance",
        content: [
          {
            para: "One of the most important legal rights of senior citizens in India is the right to maintenance from their children or other relatives. This means that children are legally obligated to provide financial support to their parents or other senior relatives who are unable to support themselves.",
          },
          {
            para: "The amount of maintenance that a senior citizen is entitled to depends on their circumstances. However, the Maintenance and Welfare of Parents and Senior Citizens Act, 2007, sets out a minimum amount of maintenance that must be provided.",
          },
        ],
      },
      {
        id: "senior-s3",
        heading: "Right to Live in a Safe and Secure Environment",
        content: [
          {
            para: "Senior citizens have the right to live in a safe and secure environment. This means that they should be protected from physical, emotional, and financial abuse. They should also be protected from neglect, which is the failure to provide them with the care they need.",
          },
          {
            para: "If a senior citizen is being abused or neglected, they can file a complaint with the police or the Maintenance and Welfare of Parents and Senior Citizens Act, 2007. The authorities will then investigate the complaint and take appropriate action.",
          },
        ],
      },
      {
        id: "senior-s4",
        heading: "Right to Access Healthcare and Other Essential Services",
        content: [
          {
            para: "Senior citizens have the right to access healthcare and other essential services. This means that they should be able to access quality healthcare, such as long-term care or home care. They should also be able to access transportation and other services that allow them to participate in society.",
          },
          {
            para: "The government provides a number of services for senior citizens, such as old age homes, day care centers, and transportation services. Senior citizens can also avail of these services through private providers.",
          },
        ],
      },
      {
        id: "senior-s5",
        heading: "Right to Participate in Social and Cultural Activities",
        content: [
          {
            para: "Senior citizens have the right to participate in social and cultural activities. This means that they should be able to participate in activities such as community events, religious events, and recreational activities.",
          },
          {
            para: "The government and private organizations organize a number of activities for senior citizens. These activities help senior citizens to stay active and engaged in society.",
          },
        ],
      },
      {
        id: "senior-s6",
        heading: "Right to Make Their Own Decisions About Their Lives",
        content: [
          {
            para: "Senior citizens have the right to make their own decisions about their lives. This means that they should be able to make decisions about their finances, their healthcare, and their living arrangements.",
          },
          {
            para: "If a senior citizen is unable to make their own decisions, they can appoint a guardian who will make decisions on their behalf. The guardian must be someone who is willing to act in the best interests of the senior citizen.",
          },
        ],
      },
      {
        id: "senior-s7",
        heading: "Right to Respect",
        content: [
          {
            para: "Senior citizens have the right to be treated with respect. This means that they should not be discriminated against on the basis of their age. They should be treated with dignity and courtesy, and their opinions should be respected.",
          },
        ],
      },
      {
        id: "senior-s8",
        heading: "Right to Financial Security",
        content: [
          {
            para: "Senior citizens have the right to financial security. This means that they should have access to adequate income, housing, and healthcare. They should also be protected from exploitation and abuse.",
          },
        ],
      },
      {
        id: "senior-s9",
        heading: "Right to Participation",
        content: [
          {
            para: "Senior citizens have the right to participate in society. This means that they should be able to vote, hold office, and participate in community activities. They should also be able to access information and services that are relevant to their needs.",
          },
        ],
      },
      {
        id: "senior-s10",
        heading: "Right to Protection",
        content: [
          {
            para: "Senior citizens have the right to protection from abuse and neglect. This means that they should be protected from physical, emotional, and financial abuse. They should also be protected from neglect, which is the failure to provide them with the care they need.",
          },
        ],
      },
      {
        id: "senior-s11",
        heading: "Right to Live Independently",
        content: [
          {
            para: "Senior citizens have the right to live independently. This means that they should be able to make their own decisions and live in their own homes, as long as they are able to do so safely. They should also be able to access transportation and other services that allow them to participate in society.",
          },
        ],
      },
      {
        id: "senior-s12",
        heading: "Right to be Cared for",
        content: [
          {
            para: "If senior citizens are unable to care for themselves, they have the right to be cared for. This means that they should be able to access quality care, such as long-term care or home care. They should also be able to choose the type of care that is right for them.",
          },
          {
            para: "These are just some of the most important rights of senior citizens in India. If you believe that your rights have been violated, you should seek legal advice.",
          },
          {
            para: "Here are some resources that can help you learn more about the rights of senior citizens in India:",
          },
          {
            para: " • National Policy for Older Persons, 2011: This policy sets out the government's commitment to the rights of senior citizens.",
          },
          {
            para: " • Maintenance and Welfare of Parents and Senior Citizens Act, 2007: This Act provides for the maintenance and welfare of senior citizens.",
          },
          {
            para: " • National Commission for the Elderly: This commission is responsible for protecting the rights of senior citizens.",
          },
          {
            para: " • HelpAge India: This organization provides support and services to senior citizens.",
          },
        ],
      },
    ],
  },
  man: {},
};

export default data;
