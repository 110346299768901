import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import emailjs from "@emailjs/browser";

// import Framer from '../src/components/Framer'

// Pages
import Blogs from "./pages/Blogs";
import BlogSingle from "./pages/BlogSingle";
import EducateYourself from "./pages/EducateYourself";
import Enquiry from "./pages/Enquiry";
import Home from "./pages/Home";
import Inter from "./pages/Inter";
import KnowYourRights from "./pages/KnowYourRights";
import LearnMore from "./pages/LearnMore";
import Star from "./pages/Star";
import Team from "./pages/Team";
import Gallery from "./pages/Gallery";
import Work from "./pages/Work";
import Consultancy from "./pages/Consultancy";
import AdminLogin from "./pages/AdminLogin";
import Admin from "./pages/Admin";

import BlogSingle2 from "./pages/BlogSingle2";

import ContextProvider from "./context/Context";
import LegalPracticeAreas from "./pages/LegalPracticeAreas";

function App() {
  useEffect(() => {
    emailjs.init("1OCeeKlTF-emp0mJe");
  }, []);

  return (
    <SnackbarProvider>
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog-single" element={<BlogSingle />} />
              <Route path="/educate-yourself" element={<EducateYourself />} />
              <Route path="/enquiry" element={<Enquiry />} />
              <Route path="/consultancy" element={<Consultancy />} />
              <Route path="/" element={<Home />} />
              <Route
                path="/know-your-rights/:type"
                element={<KnowYourRights />}
              />
              <Route
                path="/legal-practice/:type"
                element={<LegalPracticeAreas />}
              />
              <Route path="/learn-more" element={<LearnMore />} />
              <Route path="/star-associate" element={<Star />} />
              <Route path="/our-team" element={<Team />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/work-with-us" element={<Work />} />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<Inter />} />

              <Route path="/blog-single2" element={<BlogSingle2 />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ContextProvider>
    </SnackbarProvider>
  );
}

export default App;
