import React from "react";
import { Box, Typography } from "@mui/material";

import icon from "../../assets/small_Icon.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import gallery1 from "./gallery1.webp";
import gallery2 from "./gallery2.webp";
import gallery3 from "./gallery3.webp";
import gallery4 from "./gallery4.webp";
import gallery5 from "./gallery5.webp";
import gallery6 from "./gallery6.webp";

const images = [
  {
    id: 1,
    img: gallery1,
    width: "48%",
  },
  {
    id: 2,
    img: gallery2,
    width: "48%",
  },
  {
    id: 3,
    img: gallery3,
    width: "100%",
  },
  {
    id: 4,
    img: gallery4,
    width: "100%",
  },
  {
    id: 6,
    img: gallery6,
    width: "100%",
  },
  {
    id: 5,
    img: gallery5,
    width: "100%",
  },
];

const Section1 = () => {
  const { lg, md, sm } = useBreakpoints();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
          mb: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "50%",
            margin: "auto",
          }}
        >
          <Typography
            variant="body2"
            color="#000"
            fontWeight={700}
            fontSize={md ? "65px" : sm ? "50px" : "30px"}
          >
            Gallery
          </Typography>
          <img src={icon} alt={icon} style={{ width: "4.5rem" }} />
        </Box>
      </Box>

      <Box
        sx={{
          width: "90%",
          margin: "auto",
          mt: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            rowGap: "2rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: sm ? "0rem" : "5rem",
          }}
        >
          {images.map((item) => (
            <img
              src={item.img}
              alt={item.id}
              key={item.id}
              style={{ width: item.width }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Section1;
