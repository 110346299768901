import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Section2 from "../sections/educate-yourself/Section2";
import Section1 from "../sections/educate-yourself/Section1";
import Section3 from "../sections/educate-yourself/Section3";
import Layout from "../components/Layout";

const EducateYourself = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Section1 />
      <Section2 />
      <Section3 />
    </Layout>
  );
};

export default EducateYourself;
