import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Section1 from "../sections/Blog Single/Section1";
import Layout from "../components/Layout";

const BlogSingle = () => {
  const location = useLocation();
  const blogData = location.state.blogData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Section1 blogData={blogData} />
    </Layout>
  );
};

export default BlogSingle;
