import { Box, ButtonBase, Divider, Typography } from "@mui/material";
import React from "react";
import icon from "../../assets/legal_icon.svg";
import suitcase from "../../assets/suitcase.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
// import { styled } from '@mui/material/styles'
import { useLocation, useNavigate } from "react-router-dom";

const LegalPracticeAreas = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lg, md, sm } = useBreakpoints();

  return (
    <Box
      sx={{
        backgroundColor: "#161D27",
        color: "#fff",
        padding: lg ? "8rem" : sm ? "4rem" : "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: sm ? "row" : "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="body2"
            fontSize={md ? "45px" : sm ? "35px" : "25px"}
            fontWeight={700}
            marginBottom="1rem"
          >
            Legal {sm && <br />} Practice Areas
          </Typography>
          <img src={icon} alt="Legal Icon" />
        </Box>

        {md && (
          <Box>
            <Divider
              orientation="vertical"
              sx={{ borderColor: "rgba(255, 255, 255, 0.3)" }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: sm ? "53%" : "100%",
            marginTop: sm ? "0rem" : "1rem",
          }}
        >
          <Typography
            variant="subtitle2"
            fontSize={md ? "20px" : "16px"}
            fontWeight={600}
          >
            <span style={{ color: "#D1B06B" }}>Parihar law LLP </span>prides
            itself on its spectacular team of legal experts who are not only
            adept in their respective fields but are also world class
            humanitarians. Under the proficient leadership of{" "}
            <span style={{ color: "#D1B06B" }}>Advocate A.P.S. Parihar</span> we
            have mastered the following, amongst other, Areas of Law:
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          marginTop: "4rem",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {legalTopics.map((item) => (
          <Box
            sx={{
              backgroundColor: "#1F2732",
              padding: "2rem",
              width: md ? "31%" : sm ? "45%" : "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            key={item.id}
          >
            <Box>
              <Typography
                variant="body2"
                fontSize={md ? "30px" : "25px"}
                fontWeight={700}
              >
                {item.title}
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={md ? "16px" : "12px"}
                fontWeight={400}
                color="#D7D7D7"
              >
                {item.desc}
              </Typography>

              <Divider
                sx={{
                  borderColor: "rgba(255, 255, 255, 0.3)",
                  marginY: "1rem",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={suitcase}
                alt="Suitcase"
                style={{ width: sm ? "15%" : "12%" }}
              />
              <ButtonBase
                onClick={() => navigate(`/legal-practice/${item.route}`)}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={md ? "22px" : sm ? "18px" : "15px"}
                  fontWeight={500}
                  sx={{
                    backgroundColor: "#161D27",
                    padding: sm ? "1rem 2rem" : "0.5rem 1rem",
                    transition: "ease",
                    transitionDuration: "300ms",

                    "&:hover": {
                      backgroundColor: "#D1B06B",
                      color: "#000",
                    },
                  }}
                >
                  Learn More
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const legalTopics = [
  {
    id: "1",
    title: "Family Law",
    desc: "Matters pertaining to family law demand a certain level of emotional intelligence along with deep knowledge of prevalent statutes like Hindu Marriage Act, 1955 and Special Marriage Act, 1954 etc. We have a vast experience and expertise in matters of Divorce, Maintenance, Restitution of conjugal rights, Custody of Children, Domestic violence, etc.",
    route: "family-law",
  },
  {
    id: "2",
    title: "Consumer Law",
    desc: "We possess great expertise and experience in disputes relating to the purchase of a service or a product from a business or a firm. Filing of consumer complaints and/or appeals at District, State and National consumer commissions, related to Insurance, banking, real estate, or any other service/product.",
    route: "consumer-law",
  },
  {
    id: "3",
    title: "Intellectual Property Rights",
    desc: "In recent times, the protection of your intellectual property has become equally important as protecting your immovable/movable properties. We deal with Registration of Trademarks, Copyrights and Patents as well as litigation arising out of infringement of your intellectual property rights.",
    route: "intellectual-property",
  },
  {
    id: "4",
    title: "Insurance Law",
    desc: "Insurance law touches every aspect of our lives today. Be it your car, house, shop, business, travel, health or even your life; everything is insured. We pride ourselves in having an unmatched level of experience and expertise in the domain of insurance law and disputes related to the same before all courts and tribunals.",
    route: "insurance-law",
  },
  {
    id: "5",
    title: "Writ Petitions",
    desc: "Writ Jurisdiction can be invoked before the Hon’ble Supreme court of India and High courts under Article 32 and Article 226 of the Constitution of India respectively for seeking various remedies which can be divided into many parts such as Habeas corpus, Mandamus, Certiorari, Prohibition, Writ of Quo- Warranto.",
    route: "writ-petitions",
  },
  {
    id: "6",
    title: "Transfer Petitions",
    desc: "We are proficient in contesting petitions for transfer of matters from one city to another within the same state (To be filed before Respective Hon’ble High Court) and for transfer of matters from one city to another, amongst different states (To be filed Before the Hon’ble Supreme Court).",
    route: "transfer-petitions",
  },
  {
    id: "7",
    title: "Civil Recovery",
    desc: "Matters pertaining to Civil Recovery can be broadly classified in two prongs, recovery of Money or recovery of Property under the provisions of Civil Procedure Code,1908 and other applicable statutes. We are proficient in all such matters and have a very rich experience in such disputes.",
    route: "civil-recovery",
  },
  {
    id: "8",
    title: "Property Disputes",
    desc: "Civil property disputes are generally with respect to Possession, Ownership or Title of a physical piece of land, either Agricultural, Residential or Commercial. Said disputes may arise with a third party or even within family and each such dispute is very unique in itself. We are proficient in resolving such disputes.",
    route: "property-law",
  },
  {
    id: "9",
    title: "Arbitration and Conciliation",
    desc: "The two prongs of Alternate Disputes Resolution in the Indian Legal Scenario, under the provisions of the Arbitration and Conciliation Act, 1996. We deal with Institutional Arbitration, Fast Track Arbitration, Ad-Hoc Arbitration, Domestic Arbitration, International Arbitration and International Commercial Arbitration.",
    route: "arbitration-law",
  },
  {
    id: "10",
    title: "Dishonor of Cheques",
    desc: "Under Indian law, a cheque is said to be dishonoured when the bank refuses to honour it, usually due to insufficient funds in the account of the issuer. The law governing dishonour of cheques in India is the Negotiable Instruments Act, 1881 (Section 138) and we are a cognoscente  in such matters.",
    route: "dishonour-of-cheques",
  },
  {
    id: "11",
    title: "Banking Law",
    desc: "Our team is adept in matters concerning disputes relating to Banking services. Specifically with respect to disputes in providing of loan, credit etc, or wrongful declaration of NPAs governed under the Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest Act, 2002",
    route: "banking-law",
  },
  {
    id: "12",
    title: "Succession and inheritance",
    desc: "After the death of a closed one it becomes pertinent to determine the legal heirs of the deceased and in what manner the properties belonging to him/her/them shall be distributed or inherited. In certain cases, the organization holding the property demands for a succession certificate (issued under section 372 of the Indian Succession Act, 1925).",
    route: "succession-inheritance",
  },
  {
    id: "13",
    title: "Real Estate (Regulation and Development) Act, 2016",
    desc: "The RERA act protects the rights of Buyers of properties from improper services by builders and colonizers which includes delay in handing over possession of property, lacunas in providing services like water, electricity, proper roads, infrastructure in colony, and/or what was promised by the builder in the initial agreement.",
    route: "real-estate",
  },
  {
    id: "13",
    title: "Right to Information Act, 2005",
    desc: "The Right to Information (RTI) in India is a crucial tool for empowering citizens and promoting transparency in governance. Enshrined as a fundamental right under the Right to Information Act, 2005, this legislation provides individuals with the means to access government information, seek accountability, and participate in the democratic process.",
    route: "right-to-information",
  },
  {
    id: "14",
    title: "Labor laws",
    desc: "We are dedicated to protection of your rights as an employee from Wrongful termination of services, Wrongful holding of Gratuity and other compensation for damage suffered during the course of employment under Various statutes enabled for protection of Employee Rights.",
    route: "labor-law",
  },
  {
    id: "15",
    title: "Madhya Pradesh Co-operative Societies Act, 1960",
    desc: "The Madhya Pradesh Co-operative Societies Act, 1960 deals with registration and functioning of Co-operative housing societies and Co-operative societies of other nature and any disputes arising out of the same are dealt with under this act. We are proficient in handling disputes concerning Societies registered under this act.",
    route: "mp-act",
  },
  {
    id: "16",
    title: "Property Search and due diligence",
    desc: "When you decide to purchase an immovable property, be it for residential, commercial or agricultural purposes, it becomes very important to determine the correct legal status and validity of the title of the same. If you do not hire a legal team to ensure that the title of the property is valid and clear from all encumbrances before the purchase, it may result in huge financial losses and legal complications in the future.",
    route: "property-search",
  },
  {
    id: "17",
    title: "Environmental Laws",
    desc: "Our firm is very dedicated towards protection of the Environment regularly files petitions to meet that objective. Certain Statutes governing the same are National Green Tribunal Act, 2010, The Environmental (Protection) Act, 1986, The Water (Prevention and Control of Pollution) Act, 1974, The Forest (Conservation) Act, 1980 and Forest Conservation: The Forest (Conservation) Act, 1980 etc.",
    route: "environmental-laws",
  },
  {
    id: "18",
    title: "Animal Rights",
    desc: "If you are an animal lover, we can assist/help you by approaching the authorities for protection of animal rights and filing of complaints against animal cruelty. Some statutes that enable us in doing so are Draught and Pack Animals Rules, 1965, Transport of Animals Rules, 1978, Animal Birth Control (Dog) Rules, 2001 and The Wildlife Protection Act, 1972 etc.",
    route: "animal-rights",
  },
  {
    id: "19",
    title: "Criminal Matters",
    desc: "We can get you instant relieves in matters concerning Bails, Private Criminal Complaints and Criminal Defense under the provisions of the Indian Penal Code, 1860, Code of Criminal Procedure, 1873 and the Indian Evidence Act, 1872. Or team is also adept with interpretation and adaptation of the provisions of the Bharatiya Nyaya Samhita, 2023.",
    route: "criminal-law",
  },
  {
    id: "20",
    title: "Cyber Laws",
    desc: "Cyber law in India plays a critical role in addressing the challenges posed by the rapid growth of technology and the increasing reliance on digital platforms. Cyber-crimes have become very rampant in recent times and we can help you resolve such issue and raise complains for the same.  The Information Technology Act, 2000 (IT Act) as well as the Indian Penal Code, 1860 provides protection from such cyber-crimes and frauds.",
    route: "cyber-laws",
  },
];

export default LegalPracticeAreas;
