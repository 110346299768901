import { useMediaQuery } from '@mui/material'

const useBreakpoints = () => {
  const xs = useMediaQuery((theme) => theme.breakpoints.up('xs')) // 0px
  const sm = useMediaQuery((theme) => theme.breakpoints.up('sm')) // 600px
  const md = useMediaQuery((theme) => theme.breakpoints.up('md')) // 900 px
  const lg = useMediaQuery((theme) => theme.breakpoints.up('lg')) // 1200px
  const xl = useMediaQuery((theme) => theme.breakpoints.up('xl')) // 1536px

  return {
    xs,
    sm,
    md,
    lg,
    xl,
  }
}

export default useBreakpoints
