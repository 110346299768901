import React, { useEffect } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import Layout from "../components/Layout";
import expertise_icon from "../assets/expertise_icon.svg";
import useBreakpoints from "../hooks/useBreakpoints";
import { useParams } from "react-router-dom";
import data from "../data/KnowYourRightsData";
import Inter from "./Inter";

const KnowYourRights = () => {
  const { md, sm } = useBreakpoints();

  const { type } = useParams();

  console.log("type =>", type);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (type === "man") return <Inter />;

  return (
    <Layout>
      <Box sx={{ position: "relative" }}>
        <img
          src={data[type].banner}
          // src={knowYourRights}
          alt="Know Your Rights"
          style={{ width: "100%", maxHeight: 600, objectFit: "cover" }}
        />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.3)",
            width: "100%",
            height: "100%",
            padding: 5,
          }}
        >
          <Typography
            variant="body2"
            fontSize={md ? "65px" : sm ? "50px" : "30px"}
            fontWeight={700}
            color="#fff"
            sx={{ textShadow: "0px 2px 2px rgba(0,0,0,0.46)" }}
          >
            {data[type].heading}
          </Typography>
          <img src={expertise_icon} alt="icon" />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: sm ? "row" : "column",
          padding: md ? "5rem" : "2rem",
        }}
      >
        <Box sx={{ width: sm ? "22%" : "100%" }}>
          <Typography fontSize={md ? "18px" : "15px"} fontWeight={700}>
            Table of Content
          </Typography>

          <Box marginTop="1.5rem" display="flex" flexDirection="column" gap={1}>
            {data[type].sections.map((item) => (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <GoChevronRight size={24} color="#696984" /> */}
                  <Typography
                    key={item.id}
                    fontSize={md ? "18px" : "15px"}
                    fontWeight={400}
                    color="#3366CC"
                    sx={{
                      userSelect: "none",
                      cursor: "pointer",
                      "&:hover": {
                        textDecorationLine: "underline",
                        fontWeight: 500,
                      },
                    }}
                    onClick={() => {
                      const element = document.getElementById(item.heading);
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    {item.heading}
                  </Typography>
                </Box>
                {item.content.map((c) =>
                  c.heading ? (
                    <Typography
                      fontSize={md ? "15px" : "12px"}
                      fontWeight={400}
                      color="#3366CC"
                      sx={{
                        marginLeft: "1rem",
                        userSelect: "none",
                        cursor: "pointer",
                        "&:hover": {
                          textDecorationLine: "underline",
                          fontWeight: 500,
                        },
                      }}
                      onClick={() => {
                        const element = document.getElementById(c.heading);
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      {c.heading}
                    </Typography>
                  ) : null
                )}
              </>
            ))}
          </Box>
        </Box>

        <Box
          sx={{ width: sm ? "75%" : "100%", marginTop: sm ? "0rem" : "4rem" }}
        >
          {data[type].sections.map((item) => (
            <Box sx={{ marginTop: "2rem" }}>
              <Typography
                variant="body2"
                fontSize={md ? "45px" : "35px"}
                fontWeight={700}
                id={item.heading}
              >
                {item.heading}
              </Typography>

              <Grid container>
                <Grid item xs={2}>
                  <Divider
                    variant="fullWidth"
                    sx={{
                      backgroundColor: "#D0A144",
                      borderWidth: "1.5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Divider
                    variant="fullWidth"
                    sx={{ backgroundColor: "#C4C4C4" }}
                  />
                </Grid>
              </Grid>

              {item.content.map((kit) => (
                <Typography
                  key={kit.id}
                  variant="subtitle2"
                  fontSize={md ? "20px" : "17px"}
                  fontWeight={400}
                  marginTop="1rem"
                >
                  {kit.para}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default KnowYourRights;
