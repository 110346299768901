import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#D1B06B',
    },
    secondary: {
      main: '#3B434E',
    },
  },
  typography: {
    allVariants: {
      fontFamily: `'Inter', sans-serif`,
    },
    body1: {
      fontFamily: `'Inter', sans-serif`,
    },
    subtitle2: {
      fontFamily: `'Outfit', sans-serif`,
    },
    body2: {
      fontFamily: `'Cormorant Garamond', sans-serif`,
    },
  },
})
