import React, { useContext, useEffect, useState } from "react";

import Banner from "../sections/home/Banner";
import AreaOfExpertise from "../sections/home/AreaOfExpertise";
import Consultation from "../sections/home/Consultation";
import LegalPracticeAreas from "../sections/home/LegalPracticeAreas";
import Message from "../sections/home/Message";
import Layout from "../components/Layout";
import DisclaimerDialog from "../components/Dialogs/DisclaimerDialog";
import { Context } from "../context/Context";

const Home = () => {
  const [showDisclaimerDialog, setShowDisclaimerDialog] = useState(false);
  const { isFirstLoad, setIsFirstLoad } = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isFirstLoad) {
      setShowDisclaimerDialog(true);
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  return (
    <Layout>
      <Banner />
      <AreaOfExpertise />
      <Consultation />
      <LegalPracticeAreas />
      <Message />

      <DisclaimerDialog
        show={showDisclaimerDialog}
        setShow={setShowDisclaimerDialog}
      />
    </Layout>
  );
};

export default Home;
