import React, { useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import img from "../Blog Single/image 11.png";
import { RxAvatar } from "react-icons/rx";
import { RiCalendar2Line } from "react-icons/ri";
import { HiOutlineClock } from "react-icons/hi";

import moment from "moment";

const Section1 = ({ blogData }) => {
  const title = blogData.title;
  const author = blogData.author;
  const createdAt = blogData.createdAt;
  const timeToRead = blogData.minsToRead;
  const thumbnail2Url = blogData.thumbnail2Url;
  const body = blogData.body;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("blogData", blogData);

  return (
    <Box
      display="flex"
      sx={{ mt: 2 }}
      flexDirection="column"
      margin="auto"
      width="70%"
    >
      <img src={thumbnail2Url} alt="bg" width="100%" style={{ marginTop: "16px" }} />

      <Typography
        variant="body2"
        sx={{ mt: 3 }}
        fontWeight={800}
        fontSize="2.175rem"
      >
        {title}
      </Typography>
      <Box display="flex" gap={2} sx={{ mt: 2 }}>
        <Box display="flex" alignItems="center">
          <RxAvatar />
          <Typography sx={{ ml: "5px" }}>{author}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <RiCalendar2Line />
          <Typography sx={{ ml: "5px" }}>
            {moment(createdAt).format("DD-MM-YYYY")}
          </Typography>
        </Box>
        <Box>
          <Divider orientation="vertical" sx={{ backgroundColor: "#999999" }} />
        </Box>
        <Box display="flex" alignItems="center">
          <HiOutlineClock />
          <Typography sx={{ ml: "5px" }}>{timeToRead} Min. To Read</Typography>
        </Box>
      </Box>
      <Typography
        gutterBottom
        sx={{ mt: 3 }}
        variant="subtitle2"
        color="#6D6D6D"
      >
        <div dangerouslySetInnerHTML={{__html: body}} />
        {/* <div dangerouslySetInnerHTML={{ __html: body.replace(/(?<!•)\n/g, '<br />') }} /> */}
      </Typography>
    </Box>
  );
};

export default Section1;
