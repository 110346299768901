import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import img from "../educate-yourself/img.svg";
// import icon from '../../assets/Icon_small.svg'
import bg from "../../assets/Educate Yourself.jpg";
import useBreakpoints from "../../hooks/useBreakpoints";

const Section1 = () => {
  const { lg, md, sm } = useBreakpoints();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <img src={bg} alt={img} style={{ width: "100%", height: "30%" }} />
      </Box>
      <Box
        sx={{ padding: md ? "3rem 6rem" : sm ? "2rem 3rem" : "2rem 1.5rem" }}
      >
        <Grid container>
          <Grid item xs={2}>
            <Divider
              sx={{
                backgroundColor: "#D0A144",
                mt: 3,
                borderWidth: "1.5px",
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Divider sx={{ backgroundColor: "#C4C4C4", mt: 3 }} />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Typography
            variant="body2"
            gutterBottom
            fontWeight={700}
            fontSize={sm ? "45px" : "30px"}
          >
            The Indian Legal Framework: A Comprehensive Overview
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ mb: 3, color: "#6D6D6D" }}
                fontSize={sm ? "20px" : "17px"}
              >
                India has a long and complex legal history, dating back to the
                days of the Mughal Empire. The current legal framework is based
                on the Constitution of India, which was adopted in 1950. The
                Constitution is the supreme law of the land, and all other laws
                must be consistent with it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ mb: 3, color: "#6D6D6D" }}
                fontSize={sm ? "20px" : "17px"}
              >
                The Indian legal system is a mixed system, incorporating
                elements of both civil law and common law. The civil law
                tradition is derived from the Roman law tradition, while the
                common law tradition is derived from the English legal system.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ mb: 3, color: "#6D6D6D" }}
                fontSize={sm ? "20px" : "17px"}
              >
                The main sources of law in India are the Constitution, statutes,
                customary law, and judicial precedent. The Constitution is the
                supreme law of the land, and all other laws must be consistent
                with it. Statutes are laws passed by the Parliament of India or
                by the legislatures of the states. Customary law is the law that
                is derived from the customs and traditions of the people of
                India. Judicial precedent is the law that is derived from the
                decisions of the courts.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ mb: 3, color: "#6D6D6D" }}
                fontSize={sm ? "20px" : "17px"}
              >
                The Indian legal system is divided into two main branches: the
                civil law system and the criminal law system. The civil law
                system deals with disputes between individuals or organizations.
                The criminal law system deals with crimes, which are offenses
                against the state.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ mb: 3, color: "#6D6D6D" }}
                fontSize={sm ? "20px" : "17px"}
              >
                The Indian legal system is also divided into a number of
                specialized areas, such as family law, contract law, property
                law, and criminal law. Each of these areas has its own set of
                laws and procedures.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={7} xl={8}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                sx={{ mb: 3, color: "#6D6D6D" }}
                fontSize={sm ? "20px" : "17px"}
              >
                The Indian legal system is a complex and evolving system. It is
                constantly being updated to meet the needs of a changing
                society. The Constitution of India provides a strong foundation
                for the legal system, and the courts have played an important
                role in interpreting and applying the Constitution.
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                fontSize={sm ? "20px" : "17px"}
                sx={{ color: "#6D6D6D" }}
              >
                The Indian legal system is a key part of the country's
                democratic framework. It provides a means for resolving disputes
                and ensuring that the rights of individuals and organizations
                are protected. The legal system also plays an important role in
                promoting economic development and social justice.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              <img
                src={img}
                alt="couple"
                style={{
                  width: "100%",
                  height: "80%",
                  objectFit: "cover",
                  marginTop: -40,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Section1;
