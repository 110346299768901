import React from 'react'
import { Box, Button, Dialog, Typography } from '@mui/material'

const AlertDialog = ({ show, setShow, yesAction }) => {
  return (
    <Dialog open={show} onClose={() => setShow(false)}>
      <Box padding="2rem">
        <Typography fontSize="30px" fontWeight={600}>
          Are you sure?
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            mt: '2rem',
          }}
        >
          <Button variant="contained" fullWidth onClick={yesAction}>
            <Typography>Yes</Typography>
          </Button>

          <Button variant="outlined" fullWidth onClick={() => setShow(false)}>
            <Typography>No</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AlertDialog
