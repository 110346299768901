import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { TbMathGreater } from "react-icons/tb";
import useBreakpoints from "../../hooks/useBreakpoints";

const Section2 = () => {
  const { lg, md, sm } = useBreakpoints();

  const tableOfContents = [
    {
      id: 1,
      title: "Introduction",
    },
    {
      id: 2,
      title: "The Indian legal system and the economy",
    },
    {
      id: 3,
      title: "The Indian legal system and society",
    },
    {
      id: 4,
      title: "The future of the Indian legal framework",
    },
  ];

  return (
    <Box sx={{ padding: md ? "0rem 6rem" : sm ? "0rem 3rem" : "0rem 1.5rem" }}>
      <Grid container spacing={lg ? 10 : 6}>
        <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
          <Box sx={{ width: "100%" }}>
            <Typography fontSize={md ? "18px" : "15px"} fontWeight={700}>
              Table of Content
            </Typography>

            <Box
              marginTop="1.5rem"
              display="flex"
              flexDirection="column"
              gap={1}
            >
              {tableOfContents.map((item) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    fontSize={md ? "18px" : "15px"}
                    fontWeight={400}
                    color="#3366CC"
                    sx={{
                      userSelect: "none",
                      cursor: "pointer",
                      "&:hover": {
                        textDecorationLine: "underline",
                        fontWeight: 500,
                      },
                    }}
                    onClick={() => {
                      const element = document.getElementById(item.title);
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={9} lg={9} xl={10}>
          <Box>
            <Box>
              <Typography
                variant="body2"
                gutterBottom
                fontWeight={700}
                fontSize={sm ? "45px" : "30px"}
                id="Introduction"
              >
                Introduction
              </Typography>
              <Grid container>
                <Grid item xs={2}>
                  <Divider
                    sx={{
                      backgroundColor: "#D0A144",
                      borderWidth: "1.5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Divider sx={{ backgroundColor: "#C4C4C4" }} />
                </Grid>
              </Grid>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
                sx={{ mb: 3, mt: 2 }}
              >
                Key features of the Indian legal framework
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                The Indian legal framework has a number of key features,
                including:
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A federal system of government, with a national
                government and state governments.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A written constitution, which is the supreme law of the
                land.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A system of separation of powers, with the executive,
                legislative, and judicial branches of government each having
                distinct powers.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A system of checks and balances, which ensures that no
                one branch of government becomes too powerful.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A system of fundamental rights, which are guaranteed to
                all citizens by the Constitution.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A system of judicial review, which allows the courts to
                strike down laws that are inconsistent with the Constitution.
              </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography
                variant="body2"
                gutterBottom
                fontWeight={700}
                fontSize={sm ? "45px" : "30px"}
                id="The Indian legal system and the economy"
              >
                The Indian legal system and the economy
              </Typography>
              <Grid container>
                <Grid item xs={2}>
                  <Divider
                    sx={{
                      backgroundColor: "#D0A144",
                      borderWidth: "1.5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Divider sx={{ backgroundColor: "#C4C4C4" }} />
                </Grid>
              </Grid>
              <Typography
                sx={{ mb: 3, mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                The Indian legal system plays an important role in the economy.
                It provides a framework for resolving disputes, protecting
                property rights, and enforcing contracts. The legal system also
                plays a role in promoting economic development by providing a
                stable and predictable environment for businesses to operate in.
              </Typography>
            </Box>
            <Box sx={{ mt: 5, mb: 5 }}>
              <Typography
                variant="body2"
                gutterBottom
                fontWeight={700}
                fontSize={sm ? "45px" : "30px"}
                id="The Indian legal system and society"
              >
                The Indian legal system and society
              </Typography>
              <Grid container>
                <Grid item xs={2}>
                  <Divider
                    sx={{
                      backgroundColor: "#D0A144",
                      borderWidth: "1.5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Divider sx={{ backgroundColor: "#C4C4C4" }} />
                </Grid>
              </Grid>
              <Typography
                sx={{ mb: 3, mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                The Indian legal system also plays an important role in society.
                It provides a means for resolving disputes, protecting
                individual rights, and ensuring that the law is applied fairly.
                The legal system also plays a role in promoting social justice
                by addressing issues such as discrimination and poverty.
              </Typography>
            </Box>
            <Box sx={{ mt: 5, mb: 5 }}>
              <Typography
                variant="body2"
                gutterBottom
                fontWeight={700}
                fontSize={sm ? "45px" : "30px"}
                id="The future of the Indian legal framework"
              >
                The future of the Indian legal framework
              </Typography>
              <Grid container>
                <Grid item xs={2}>
                  <Divider
                    sx={{
                      backgroundColor: "#D0A144",
                      borderWidth: "1.5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Divider sx={{ backgroundColor: "#C4C4C4" }} />
                </Grid>
              </Grid>
              <Typography
                sx={{ mb: 3, mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                The Indian legal framework is constantly evolving to meet the
                needs of a changing society. In recent years, there have been a
                number of reforms to the legal system, such as the introduction
                of a new civil code and the establishment of a national legal
                services authority. These reforms are aimed at making the legal
                system more accessible and efficient.
              </Typography>
              <Typography
                sx={{ mb: 3, mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                The future of the Indian legal framework is bright. The country
                has a strong legal tradition, and the government is committed to
                further reform. The legal system will continue to play an
                important role in the development of India as a vibrant
                democracy and a strong economy. services authority. These
                reforms are aimed at making the legal system more accessible and
                efficient.
              </Typography>
              <Typography
                sx={{ mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                In addition to the key features listed above, the Indian legal
                framework also has a number of other important characteristics.
                These include:
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A strong emphasis on the rule of law.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A commitment to judicial independence.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A focus on access to justice for all.
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                &#8226; A respect for human rights.
              </Typography>
              <Typography
                sx={{ mb: 3, mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                The Indian legal framework is a complex and evolving system.
                However, it is also a system that is committed to upholding the
                rule of law and protecting the rights of all citizens. The
                future of the Indian legal framework is bright, and it will
                continue to play an important role in the development of India
                as a vibrant democracy and a strong economy.
              </Typography>
              <Typography
                sx={{ mb: 3, mt: 2 }}
                variant="subtitle2"
                fontSize={sm ? "20px" : "17px"}
                fontWeight={400}
                color="#6D6D6D"
              >
                In conclusion, the Indian legal framework is a complex and
                evolving system that has a number of key features. These
                features include a strong emphasis on the rule of law and a
                commitment to judicial independence.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Section2;
