import React from 'react'
import { Box, Typography, Avatar, Divider } from '@mui/material'
import { MdOutlineCalendarMonth } from 'react-icons/md'
import { FiClock } from 'react-icons/fi'
import useBreakpoints from '../../hooks/useBreakpoints'

const BlogDetails = ({ author, dateOfUpload, timeToRead }) => {
  const { sm } = useBreakpoints()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: sm ? 2 : 0.5,
        marginTop: '3rem',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        <Avatar sx={{ height: 20, width: 20, fontSize: 14 }}>A</Avatar>
        <Typography fontSize={sm ? '15px' : '5px'} fontWeight={400}>
          {author}
        </Typography>
      </Box>

      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderRightWidth: '2px' }}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: sm ? 2 : 1 }}>
        <MdOutlineCalendarMonth size={20} />
        <Typography fontSize={sm ? '15px' : '5px'} fontWeight={400}>
          {dateOfUpload}
        </Typography>
      </Box>

      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderRightWidth: '2px' }}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: sm ? 2 : 1 }}>
        <FiClock size={20} />
        <Typography fontSize={sm ? '15px' : '5px'} fontWeight={400}>
          {timeToRead}. To Read
        </Typography>
      </Box>
    </Box>
  )
}

export default BlogDetails
