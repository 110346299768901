import React from 'react'
import Appbar from './Appbar/Appbar'
import Footer from './Footer/Footer'
import useBreakpoints from '../hooks/useBreakpoints'

const Layout = ({ children, header = true, footer = true }) => {
  const { xl, md, sm } = useBreakpoints()

  return (
    <div>
      {header && <Appbar />}
      <div
        style={{
          marginTop: xl ? '160px' : md ? '140px' : sm ? '140px' : '100px',
        }}
      >
        {children}
      </div>
      {footer && <Footer />}
    </div>
  )
}

export default Layout
