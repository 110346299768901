import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import expertiseIcon from "../../assets/expertise_icon.svg";
import hammer from "../../assets/hammer.svg";
import hammerWhite from "../../assets/hammer_white.svg";
import taraju from "../../assets/taraju.svg";
import tarajuWhite from "../../assets/taraju_white.svg";
import checklist from "../../assets/checklist.svg";
import checklistWhite from "../../assets/checklist_white.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";

const cards = [
  {
    id: "hammer",
    icon: hammer,
    icon_white: hammerWhite,
    path: "/legal-practice/family-law",
    title: "Family Law",
    text: "Divorce, Maintenance, Restitution of conjugal rights, Custody of your Children, Domestic violence, etc.",
  },
  {
    id: "taraju",
    icon: taraju,
    icon_white: tarajuWhite,
    title: "Consumer Law",
    path: "/legal-practice/consumer-law",
    text: "Non-payment of Insurance claims, Irregularities in any kind of services, Wrongful deductions from your Bank Account, etc.",
  },
  {
    id: "checklist",
    icon: checklist,
    icon_white: checklistWhite,
    title: "Intellectual Property Rights",
    path: "/legal-practice/intellectual-property",
    text: "Registration of Trademarks, Copyrights and Patents; litigation arising out of infringement of your intellectual property rights.",
  },
];

const AreaOfExpertise = () => {
  const navigate = useNavigate();
  const [hover, setHover] = useState({
    hammer: false,
    taraju: false,
    checklist: false,
  });

  const { lg, md, sm } = useBreakpoints();

  return (
    <Box
      sx={{
        backgroundColor: "#161D27",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingY: "5rem",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
        }}
      >
        <img
          src={expertiseIcon}
          alt="Area Of Expertise"
          style={{ width: "15%" }}
        />
        <Typography
          variant="body2"
          fontSize={md ? "45px" : sm ? "35px" : "20px"}
          fontWeight={700}
        >
          Areas of Expertise
        </Typography>
        <img
          src={expertiseIcon}
          alt="Area Of Expertise"
          style={{ width: "15%" }}
        />
      </Box>

      <Typography
        variant="subtitle2"
        fontSize={sm ? "19px" : "14px"}
        marginTop={lg ? "5rem" : "2rem"}
        align="center"
        maxWidth={md ? "50%" : sm ? "75%" : "90%"}
        fontWeight={400}
      >
        Legal Problems Don’t Knock, before they enter your life. It’s a storm
        without any warning. And when you find yourself face to face with a
        legal complication, you need someone you can trust, and who is an expert
        of the field. Our team of expert counsels and consultants cover a wide
        array of subjects, primary amongst them are:
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: sm ? "row" : "column",
          gap: "2rem",
          maxWidth: lg ? "65%" : md ? "80%" : "90%",
          marginTop: "2rem",
          marginBottom: -20,
        }}
      >
        {cards.map((item) => (
          <Box
            key={item.id}
            sx={{
              padding: sm ? "2rem" : "1rem",
              backgroundColor: hover[item.id] ? "#B99A51" : "#fff",
              transition: "ease",
              transitionDuration: "500ms",
              boxShadow: "0px 13px 19px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
            onMouseEnter={() =>
              setHover((prev) => ({ ...prev, [item.id]: true }))
            }
            onMouseLeave={() =>
              setHover((prev) => ({ ...prev, [item.id]: false }))
            }
            onClick={() => navigate(item.path)}
          >
            <img
              src={hover[item.id] ? item.icon_white : item.icon}
              alt={item.title}
            />
            <Typography
              color={hover[item.id] ? "#fff" : "#000"}
              variant="body2"
              fontSize={sm ? "28px" : "20px"}
              fontWeight={700}
              sx={{ userSelect: "none" }}
            >
              {item.title}
            </Typography>
            <Typography
              color={hover[item.id] ? "#fff" : "#737373"}
              fontSize={sm ? "14px" : "11px"}
              marginTop="0.5rem"
              sx={{ userSelect: "none" }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AreaOfExpertise;
