import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { Context } from "../../context/Context";

const BlogDialog = ({ show, setShow, dialogType, blogId }) => {
  const {
    addBlog,
    updateBlog,
    getBlogs,
    getSingleBlog,
    currentBlog,
    setCurrentBlog,
    updateBlogMedia,
  } = useContext(Context);

  const [values, setValues] = useState({
    title: "",
    author: "",
    minsToRead: "",
    thumbnail1Url: "",
    thumbnail2Url: "",
  });

  const [docxFile, setDocxFile] = useState(null);

  function closeDialog() {
    setShow(false);
    setTimeout(() => {
      setValues({
        title: "",
        author: "",
        minsToRead: "",
        thumbnail1Url: "",
        thumbnail2Url: "",
      });
      setCurrentBlog({});
    }, 500);
  }

  async function handleSubmit() {
    if (dialogType === "Edit") {
      const blog = await updateBlog(blogId, values);
      await updateBlogMedia(blog.data._id,docxFile);
      closeDialog();
      getBlogs();
      return;
    }

    const blog = await addBlog(values);
    console.log("blog", blog);
    await updateBlogMedia(blog.data._id,docxFile);
    getBlogs();
    closeDialog();
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Handle the selected file as needed
    console.log("Selected File:", selectedFile);
    setDocxFile(selectedFile);
  };

  useEffect(() => {
    if (blogId) getSingleBlog(blogId);
  }, [blogId]);

  useEffect(() => {
    if (Object.keys(currentBlog).length > 0) {
      setValues({
        title: currentBlog.title,
        author: currentBlog.author,
        minsToRead: currentBlog.minsToRead,
        thumbnail1Url: currentBlog.thumbnail1Url,
        thumbnail2Url: currentBlog.thumbnail2Url,
      });
    }
  }, [currentBlog]);

  return (
    <Dialog open={show} onClose={closeDialog} fullWidth maxWidth="sm">
      <IconButton
        sx={{ position: "absolute", top: 20, right: 20 }}
        onClick={closeDialog}
      >
        <MdClose size={30} color="#000" />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          gap: 3,
        }}
      >
        <Typography fontSize="35px" fontWeight={500}>
          {dialogType} Blog
        </Typography>

        {fields.map((item) => (
          <TextField
            key={item.id}
            variant="outlined"
            label={item.label}
            multiline={item.id === "body"}
            rows={6}
            value={values[item.id]}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                [item.id]: e.target.value,
              }))
            }
          />
        ))}

        <TextField
          type="file"
          variant="outlined"
          fullWidth
          onChange={handleFileChange}
        />

        <Box textAlign="center">
          <Button variant="outlined" onClick={handleSubmit}>
            <Typography>Submit</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const fields = [
  { id: "title", label: "Title" },
  { id: "author", label: "Author" },
  { id: "minsToRead", label: "Mins To Read" },
  { id: "thumbnail1Url", label: "Thumbnail 1" },
  { id: "thumbnail2Url", label: "Thumbnail 2" },
];

export default BlogDialog;
