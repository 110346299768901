import React, { useState } from "react";
import {
  Box,
  Typography,
  ButtonBase,
  Grid,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import img from "../work/img.svg";
import img2 from "../work/Vector.svg";
import img3 from "../../assets/team1.jpg";
import tick from "../work/tick.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";

const Section1 = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [tickValue, setTickValue] = useState("intern");
  const fields = [
    {
      id: "name",
      label: "Full Name",
    },
    {
      id: "email",
      label: "Email ID",
    },
    {
      id: "phone",
      label: "Phone number",
    },
    {
      id: "qualification",
      label: "Qualification ",
    },
    {
      id: "experience",
      label: "Experience",
    },
    {
      id: "Skills",
      label: "Skills ",
    },
    {
      id: "Address",
      label: "Address",
      multiline: true,
    },
    {
      id: "Languages",
      label: "Languages",
    },
    {
      id: "job_type",
      label: "",
      options: [
        {
          id: "intern",
          label: "INTERN",
        },
        {
          id: "fulltime",
          label: "FULLTIME",
        },
      ],
    },
    {
      id: "previous_work",
      label: "Presently/last worked at",
    },
  ];
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    qualification: "",
    experience: "",
    Skills: "",
    Address: "",
    Languages: "",
    previous_work: "",
  });

  const { md, sm } = useBreakpoints();

  const templateId = "template_t29u4gn";
  const serviceId = "service_8uj940j";

  const handleClick = async () => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        qualification: values.qualification,
        experience: values.experience,
        skills: values.Skills,
        address: values.Address,
        languages: values.Languages,
        jobType: tickValue,
        previousWork: values.previous_work,
      };
      await emailjs.send(serviceId, templateId, payload);
      enqueueSnackbar("Application Submitted SuccessFully!!", {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setValues({
        name: "",
        email: "",
        phone: "",
        qualification: "",
        experience: "",
        Skills: "",
        Address: "",
        Languages: "",
        previous_work: "",
      });
      setTickValue("intern");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <img src={img3} alt={img3} style={{ width: "100%" }} />
        <Box
          sx={{
            position: sm ? "absolute" : "inherit",
            backgroundColor: "#1F2732",
            top: "5%",
            height: "fit-content",
            left: 0,
            right: 0,
            margin: "auto",
            marginTop: !sm && "-40%",
            width: md ? "60%" : sm ? "80%" : "90%",
          }}
        >
          <Box
            sx={{
              width: md ? "80%" : "90%",
              margin: "auto",
              padding: md ? "4rem 0px" : sm ? "2rem 0rem" : "1rem 0rem",
            }}
          >
            <Grid container spacing={5}>
              {fields.map((item) => (
                <Grid item xs={12} sm={6} alignItems="center">
                  {item.id === "job_type" ? (
                    <Box gap={4} display="flex">
                      {item.options.map((btn) => (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          gap={2}
                        >
                          <Button
                            variant={
                              tickValue === btn.id ? "contained" : "outlined"
                            }
                            sx={{
                              pointerEvents:
                                tickValue === btn.id ? "none" : "auto",
                            }}
                            onClick={() => {
                              setTickValue(btn.id);
                            }}
                          >
                            {btn.label}
                          </Button>

                          {tickValue === btn.id && (
                            <img src={tick} alt={tick} />
                          )}
                        </Box>
                      ))}
                    </Box>
                  ) : tickValue === "intern" &&
                    item.id === "previous_work" ? null : (
                    <CustomTextField
                      label={item.label}
                      variant="standard"
                      multiline={item.multiline}
                      rows={3}
                      fullWidth
                      value={values[item.id]}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          [item.id]: e.target.value,
                        }))
                      }
                    />
                  )}
                </Grid>
              ))}
            </Grid>

            {/* <Divider
              sx={{ background: '#3A4553', width: '80%', margin: '2rem auto' }}
            /> */}
            <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
              <ButtonBase
                variant="contained"
                sx={{
                  color: "#1F2732",
                  backgroundColor: "white",
                  padding: sm ? "1rem 1.5rem" : "0.6rem 1.2rem",
                }}
                onClick={handleClick}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={sm ? "22px" : "15px"}
                  fontWeight={500}
                >
                  Submit
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: sm ? "80%" : "90%",
          margin: "auto",
          marginBottom: "5rem",
          display: "flex",
          flexDirection: md ? "row" : "column",
          mt: 12,
        }}
      >
        <Box width={md ? "40%" : "100%"}>
          <img src={img} alt="icon" style={{ width: "100%" }} />
        </Box>

        <Box width={md ? "60%" : "100%"} mt={8}>
          <Box sx={{ mb: 3 }}>
            {/* <Typography
              variant="body2"
              fontWeight={700}
              fontSize={md ? "45px" : "35px"}
            >
              Lorem ipsum
            </Typography> */}

            <Grid container>
              <Grid item xs={2}>
                <Divider
                  sx={{
                    backgroundColor: "#D0A144",
                    borderWidth: "1.5px",
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Divider sx={{ backgroundColor: "#C4C4C4" }} />
              </Grid>
            </Grid>
          </Box>

          <Typography
            variant="subtitle2"
            color="#6D6D6D"
            fontSize={sm ? "18px" : "15px"}
            fontWeight={400}
          >
            If you're a passionate attorney seeking to make a real impact, join
            our team. We offer a collaborative work environment where you'll
            learn from experienced mentors and tackle challenging cases.
          </Typography>
          <Typography
            sx={{ mt: 2, mb: 4 }}
            variant="subtitle2"
            color="#6D6D6D"
            fontSize={sm ? "18px" : "15px"}
            fontWeight={400}
            gutterBottom
          >
            Our firm is known for its outstanding reputation and commitment to
            client success. We invest in our attorneys' professional growth and
            provide opportunities for advancement. Become part of a winning team
            and make a difference in your career.
          </Typography>
          {/* {[1, 2, 3].map((item) => (
            <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
              <img src={img2} alt={img2} />
              <Typography
                variant="subtitle2"
                color="#6D6D6D"
                fontSize={sm ? "18px" : "15px"}
                fontWeight={400}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do{" "}
              </Typography>
            </Box>
          ))} */}
        </Box>
      </Box>
    </>
  );
};

const CustomTextField = styled(TextField)({
  input: {
    color: "white",
  },
  textarea: {
    color: "white",
  },
  label: {
    color: "rgba(255,255,255,0.6)",
  },
  "& .MuiInputBase-root": {
    "&:hover": {
      "&:before": {
        borderBottom: "1px solid rgba(255,255,255,0.2)",
      },
    },
    "&:before": {
      borderBottom: "1px solid rgba(255,255,255,0.2)",
    },
  },
});

export default Section1;
