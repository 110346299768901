import { Box, ButtonBase, Typography } from "@mui/material";
// import { styled } from '@mui/material/styles'
import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";
import Home2 from "./Home page 2.webp";

const Consultation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lg, md, sm } = useBreakpoints();

  return (
    <>
      <Box
        sx={{
          paddingTop: "10rem",
          paddingBottom: "2rem",
          display: "flex",
          flexDirection: md ? "row" : "column",
          justifyContent: "space-between",
          width: "80%",
          margin: "auto",
        }}
      >
        <Box width={md ? "50%" : "100%"}>
          <Typography
            variant="body2"
            fontSize={sm ? "35px" : "25px"}
            fontWeight={700}
          >
            The Indian Legal System is arguably one of the most complex legal
            system of the world.
          </Typography>
          <Typography
            variant="subtitle2"
            fontSize={sm ? "20px" : "15px"}
            fontWeight={400}
            marginTop="1rem"
          >
            Honestly, even most of the lawyers are confused about it.
            Fortunately, we are not.
          </Typography>
          <ButtonBase
            onClick={() => navigate("/enquiry")}
            sx={{
              marginTop: "2rem",
              backgroundColor: "#D1B06B",
              color: "#000",
              padding: sm ? "1rem 5rem" : "0.8rem 3rem",
            }}
          >
            <Typography
              variant="subtitle2"
              fontSize={sm ? "22px" : "15px"}
              fontWeight={500}
            >
              Contact Us
            </Typography>
          </ButtonBase>
        </Box>

        <Box
          width={lg ? "40%" : md ? "45%" : "100%"}
          sx={{ border: "30px solid black", marginTop: md ? "0rem" : "2rem" }}
        >
          <Box sx={{ padding: "2rem 1.5rem", border: "15px solid #DFDFDF" }}>
            <Typography
              variant="body2"
              fontSize={sm ? "45px" : "25px"}
              fontWeight={700}
            >
              Get a Consultation
            </Typography>
            <Typography
              color="#909090"
              variant="subtitle2"
              fontSize={sm ? "20px" : "15px"}
              fontWeight={400}
            >
              All Legal problems, have a legal Solution.
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem", marginTop: "2rem" }}>
              <Box sx={{ backgroundColor: "#CCAA60", padding: "1rem" }}>
                <BsTelephoneFill color="#fff" size={32} />
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontSize={sm ? "18px" : "13px"}
                  fontWeight={500}
                >
                  Call Us On:
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={sm ? "24px" : "15px"}
                  fontWeight={600}
                >
                  +91 9574486593
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <img src={Home2} alt="Buisness Man" style={{ width: "100%" }} />
    </>
  );
};

export default Consultation;
