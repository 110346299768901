import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
} from "@mui/material";

import img from "../../assets/enquiry.webp";
import icon from "../../assets/small_Icon.svg";
import oval from "../enquiry/Oval.svg";
import dotted from "../enquiry/Dotted Shape.svg";
import home from "../enquiry/home.svg";
import telephone from "../enquiry/telephone.svg";
import mail from "../enquiry/mail.svg";
import useBreakpoints from "../../hooks/useBreakpoints";

import emailjs from "@emailjs/browser";

const Section1 = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { sm, md } = useBreakpoints();

  const templateId = "template_t29u4gn";
  const serviceId = "service_8uj940j";
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendEmail = async () => {
    try {
      await emailjs.send(serviceId, templateId, values);
      enqueueSnackbar("Enquiry Submitted SuccessFully!!", {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setValues({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "50%",
            margin: "auto",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#D0A144",
              fontSize: md ? "65px" : sm ? "50px" : "30px",
              fontWeight: 700,
            }}
          >
            Enquiry
          </Typography>
          <img src={icon} alt={icon} style={{ width: "4.5rem" }} />
        </Box>
      </Box>
      <Box
        sx={{
          width: md ? "80%" : "95%",
          margin: "auto",
          marginTop: "5rem",
          marginBottom: "4rem",
          display: "flex",
          flexDirection: md ? "row" : "column",
          gap: "5rem",
        }}
      >
        <Box sx={{ width: md ? "60%" : "100%" }}>
          <Typography sx={{ color: "#D0A144", fontSize: "0.8rem" }}>
            Contact Us
          </Typography>
          <Typography
            sx={{ color: "#212B36", fontSize: "2.2rem", fontWeight: "900" }}
            variant="body2"
          >
            Get Online Consultancy
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#637381", fontWeight: "400", mt: 2 }}
          >
            Finding a trustworthy counsel in this contemporary world is like
            finding a needle in a haystack. Trust us, you can trust us.
          </Typography>
          <Box sx={{ mt: 6 }}>
            <Box sx={{ display: "flex", gap: "2rem", alignItems: "center" }}>
              <Box
                sx={{
                  backgroundColor: "#3056D30D",
                  width: "63px",
                  height: "63px",
                  padding: "19px",
                }}
              >
                <img src={home} alt={home} />
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                >
                  Our Location
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#637381",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                  }}
                >
                  Chamber No. 448,449 and 450 UCI Lawyers Chambers, Arera Hills,
                  Bhopal. 462011.
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#637381",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                  }}
                >
                  54, Daya Nagar, Yadav colony, Jabalpur, PIN code: 482002
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#637381",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                  }}
                >
                  H-12, Lower Ground Floor, Green Park (Main) New Delhi – 110016
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#637381",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                  }}
                >
                  H. No. 122, 12th Floor, Building 6-C, Seva Samiti CHS, Sion
                  East, Antop Hill, Mumbai - 400037
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", gap: "2rem", alignItems: "center", mt: 4 }}
            >
              <Box
                sx={{
                  backgroundColor: "#3056D30D",
                  width: "63px",
                  height: "63px",
                  padding: "19px",
                }}
              >
                <img src={telephone} alt={telephone} />
              </Box>
              <Box sx={{ width: "35%" }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                >
                  Phone Number
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#637381",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                  }}
                >
                  +91 9574486593 <br />
                  +91 9425675449
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", gap: "2rem", alignItems: "center", mt: 4 }}
            >
              <Box
                sx={{
                  backgroundColor: "#3056D30D",
                  width: "63px",
                  height: "63px",
                  padding: "19px",
                }}
              >
                <img src={mail} alt={mail} />
              </Box>
              <Box sx={{ width: "35%" }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                >
                  Email Address
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#637381",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                  }}
                >
                  pariharlaw@gmail.com
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: md ? "60%" : "100%",
            margin: "auto",
            position: "relative",
            display: "flex",
            ml: "3.5rem",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              top: -38,
              right: 42,
              flexDirection: "column",
              zIndex: -1,
              justifyContent: "flex-end",
            }}
          >
            <img
              src={oval}
              alt="oval"
              style={{ width: "100px", height: "100px" }}
            />
            <img
              src={dotted}
              alt="dotted"
              style={{ width: "100px", height: "100px" }}
            />{" "}
          </Box>
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              bottom: -20,
              left: -30,
              flexDirection: "column",
              zIndex: -1,
              justifyContent: "flex-end",
            }}
          >
            <img
              src={dotted}
              alt="dotted"
              style={{ width: "100px", height: "100px" }}
            />{" "}
          </Box>
          <Card sx={{ width: "80%" }}>
            <CardContent>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <TextField
                  label="Your Name"
                  name="name"
                  variant="outlined"
                  value={values.name}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <TextField
                  label="Your Email"
                  name="email"
                  variant="outlined"
                  value={values.email}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, email: e.target.value }))
                  }
                />
                <TextField
                  label="Your Phone"
                  name="phone"
                  variant="outlined"
                  value={values.phone}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, phone: e.target.value }))
                  }
                />
                <TextField
                  label="Your Message"
                  name="message"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={values.message}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, message: e.target.value }))
                  }
                />
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  onClick={sendEmail}
                >
                  Send a Message
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Section1;
