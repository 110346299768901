import React from "react";
import { Box, Typography } from "@mui/material";
import img1 from "../star/mpt.jpg";
import { AiOutlineInstagram } from "react-icons/ai";
import { MdFacebook } from "react-icons/md";
import { AiOutlineTwitter } from "react-icons/ai";
import { ImPinterest } from "react-icons/im";

import useBreakpoints from "../../hooks/useBreakpoints";

// const Stars = [
//   {
//     id: 1,
//     title: "Shri Mohan P. Tiwari",
//     img: img1,
//     text: [
//       "Mohan P. Tiwari is a Retd. District Judge and has great experience.",
//     ],
//   },
// ];

const Section1 = () => {
  const { md, sm } = useBreakpoints();
  return (
    <Box width="100%" margin="auto" marginBottom="4rem">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box
          sx={{
            display: !md && "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              mt: "2rem",
              textAlign: "center",
            }}
          >
            <img
              src={img1}
              alt={"Shri Mohan P. Tiwari"}
              style={{
                marginBottom: "auto",
                width: "60%",
              }}
            />
          </Box>
          {/* <Box
            sx={{ m: "1.7rem 4rem 2rem 4.5rem" }}
            display="flex"
            justifyContent="center"
            gap="1rem"
          >
            <AiOutlineInstagram color="#D1B06B" fontSize="1.5rem" />
            <MdFacebook color="#D1B06B" fontSize="1.5rem" />
            <AiOutlineTwitter color="#D1B06B" fontSize="1.5rem" />
            <ImPinterest color="#D1B06B" fontSize="1.5rem" />
          </Box> */}
        </Box>

        <Box maxWidth={md ? "80%" : "90%"} mx="auto">
          <Typography
            variant="body2"
            fontWeight={700}
            fontSize={md ? "45px" : sm ? "35px" : "30px"}
            textAlign="center"
            gutterBottom
          >
            Shri Mohan P. Tiwari
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            The Honourable ex-District Judge, Mr Tiwari, boasts a distinguished
            career spanning several decades within the Indian judiciary. He
            works with Parihar Law LLP as a consultant, and as an appearing
            advocate in matters which involve high Stakes. As a matter of fact,
            the Judgement of Bhopal Gas Tragedy case was delivered by Shri
            Tiwari Himself on 07.06.2010, in the matter of Central Bureau of
            Investigation Vs. Warren Anderson
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            His profound erudition and sagacious adjudications have established
            him as an eminent authority in multiple facets of legal
            jurisprudence. His expertise encompasses a broad spectrum of legal
            domains. Demonstrating an exceptional acumen in familiae legis, he
            has presided over numerous complex matrimonial disputes, child
            custody battles, and maintenance claims, providing equitable
            resolutions with a deep understanding of domestic dynamics and
            societal impacts. Known for his adeptness in compositio litis, he
            has facilitated numerous plea agreements, ensuring judicial
            efficiency while balancing the interests of justice and the rights
            of the accused. With an insightful grasp of the intricate and
            ever-evolving domain of cyber law, he has been pivotal in
            adjudicating cases pertaining to cybercrimes, data breaches, and
            digital privacy, thereby fortifying the legal framework in the
            digital age.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            His thorough understanding of lites de re pecuniaria under the N.I.
            Act has led to several landmark judgments in cheque dishonour cases,
            reinforcing the sanctity of negotiable instruments in commercial
            transactions. Also, he has adjudicated myriad claims under the Motor
            Vehicles Act, meticulously assessing compensations in accident
            cases, thereby safeguarding the rights of victims and promoting road
            safety jurisprudence. He has consistently sought to augment his
            judicial prowess through various specialized training programs and
            workshops, reflecting his commitment to continual professional
            development and judicial excellence. Noteworthy among these are:
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            25th April 2016 – 29th April 2016: Course on Crime and Justice at
            LNJN National Institute of Criminology and Forensic Science, Delhi.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            21st December 2012 – 23rd December 2012: Specialized Training
            Programme at the State Forensic Science Laboratory, Sagar focusing
            on the Prevention of Corruption Act, 1988 and the Protection of
            Women from Domestic Violence Act, 2005.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            18th February 2012 – 20th February 2012: Specialized Training
            Programme at the Medico Legal Institute, Bhopal.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            1st February 2010 – 5th February 2010: Training Programme on
            Application of Information and Communication Technology to District
            Judiciary.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            12th July 2009: Judiciary and Forest Officers Consultation Workshop:
            Issues and Options at the Academy of Administration, Bhopal.
          </Typography>
          <Typography
            variant="subtitle2"
            color="#555555"
            sx={{ mb: 3 }}
            textAlign="center"
            fontSize={20}
            fontWeight={400}
          >
            His illustrious career is a testament to his unwavering dedication
            to the judiciary, his profound legal insights, and his relentless
            pursuit of justice. His contributions have not only enriched the
            legal fraternity but have also significantly impacted the lives of
            countless individuals seeking justice.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Section1;
