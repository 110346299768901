import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Layout from '../components/Layout'
import BlogDetails from '../components/Blogs/BlogDetails'
import useBreakpoints from '../hooks/useBreakpoints'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import blogData from '../config/blogs.json'

const Blog = () => {
  const navigate = useNavigate()
  const { xl, lg, md, sm } = useBreakpoints()
  const [blogs, setBlogs] = useState([])

  useEffect(() => {
    setBlogs(blogData)
  }, [])

  return (
    <Layout>
      <Box
        sx={{
          p: xl
            ? '2rem 8rem'
            : md
            ? '2rem 5rem'
            : sm
            ? '2rem 3rem'
            : '2rem 2rem',
          marginBottom: md ? '8rem' : sm ? '5rem' : '2rem',
        }}
      >
        {/* <Typography
          variant="body2"
          fontSize={md ? '45px' : sm ? '35px' : '30px'}
          fontWeight={700}
        >
          More Blogs
        </Typography> */}

        <Grid container spacing={5} >
          {blogs.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.title}>
              <Box
                onClick={() =>
                  navigate(`/blog-single2`, { state: { htmlFilename: item.htmlFilename } })
                }
              >
                <img
                  src={item.thumbnail1Url}
                  alt="Blog"
                  style={{ width: '100%' }}
                />
                <Typography
                  variant="body2"
                  fontSize="27px"
                  fontWeight={700}
                  mt="5px"
                  sx={{
                    transition: 'ease',
                    transitionDuration: '0.2s',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#D1B06B',
                    },
                  }}
                >
                  {item.title}
                </Typography>
                <BlogDetails
                  author={item.author.substring(0, 7)}
                  dateOfUpload={moment(item.createdAt).format('DD-MM-YYYY')}
                  timeToRead={`${item.minsToRead} Mins`}
                />
                <Typography fontSize="15px" fontWeight={400} mt="1rem">
                  {item.body}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  )
}

export default Blog
