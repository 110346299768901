import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Layout from '../components/Layout';
import { useLocation } from 'react-router-dom';

const BlogSingle2 = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const location = useLocation();
  const { htmlFilename } = location.state || {};

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        if (htmlFilename) {
          const response = await fetch(`/blogs/${htmlFilename}`);
          const content = await response.text();
          setHtmlContent(content);
        }
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtmlContent();
  }, [htmlFilename]);

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <div className='html-content' dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Box>
    </Layout>
  );
};

export default BlogSingle2;
