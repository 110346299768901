import React, { useState } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";

const KnowYourRightsMenu = ({ item, location }) => {
  const { lg, xl } = useBreakpoints();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const rightsList = [
    {
      id: 1,
      title: "As a consumer",
      route: "consumer",
    },
    {
      id: 2,
      title: "As an investor in India",
      route: "invester-in-india",
    },
    {
      id: 3,
      title: "As an employee in India",
      route: "employee-in-india",
    },
    {
      id: 4,
      title: "As a woman",
      route: "woman",
    },
    {
      id: 5,
      title: "As a senior citizen",
      route: "senior-citizen",
    },
    {
      id: 6,
      title: "As a man",
      route: "man",
    },
    {
      id: 7,
      title: "As a businessman",
      route: "businessman",
    },
  ];

  return (
    <div>
      <Typography
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          cursor: "pointer",
          display: "inline-block",
          position: "relative",
          userSelect: "none",

          "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            transform: `scaleX(${open ? "1" : "0"})`,
            height: "2px",
            bottom: 0,
            left: 0,
            backgroundColor: "#B89A51",
            transformOrigin: "bottom right",
            transition: "transform 0.25s ease-out",
          },

          "&:hover": {
            "&:after": {
              transform: "scaleX(1)",
              transformOrigin: "bottom left",
            },
          },
        }}
        variant="subtitle2"
        fontSize={xl ? "22px" : lg ? "18px" : "15px"}
        fontWeight={400}
        onClick={handleClick}
      >
        {item.title}
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ marginTop: 2, width: "100%" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {rightsList.map((i) => (
          <MenuItem onClick={() => navigate(`/know-your-rights/${i.route}`)}>
            {i.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default KnowYourRightsMenu;
