import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Section1 from "../sections/videos/Section1";

const Videos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Section1 />
    </Layout>
  );
};

export default Videos;
