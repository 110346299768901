import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Button,
  IconButton,
  Fade,
  Stack,
  Pagination,
} from "@mui/material";
import { Context } from "../context/Context";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import Moment from "react-moment";

import BlogDialog from "../components/Dialogs/BlogDialog";
import AlertDialog from "../components/Dialogs/AlertDialog";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { getBlogs, blogs, deleteBlog, getQueries, queries, isLoggedIn } =
    useContext(Context);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [blogId, setBlogId] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  async function handleDelete() {
    await deleteBlog(blogId);
    getBlogs();
    setShowAlert(false);
  }

  async function paginateBlogs() {
    console.log("paginated-blogs", blogs);
  }

  useEffect(() => {
    if (tabValue === 0) getBlogs();
    if (tabValue === 1) getQueries();
  }, [tabValue]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!isLoggedIn) {
      navigate("/admin-login");
    }
  }, []);

  return (
    <Box>
      <Typography fontSize="45px" fontWeight={600} align="center">
        Admin Panel
      </Typography>

      <Box
        sx={{
          width: "80%",
          marginX: "auto",
          border: "1px solid rgba(0,0,0,0.5)",
          borderRadius: "8px",
          padding: "2rem",
          marginBottom: "5rem",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Blogs" />
          <Tab label="Queries" />
        </Tabs>

        <Fade in={tabValue === 0} unmountOnExit>
          <Box>
            {Object.keys(blogs).length > 0 && (
              <Box sx={{ marginTop: "2rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography fontSize="20px">
                    Total Blogs: {blogs.count}
                  </Typography>{" "}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setDialogType("Add");
                      setShowDialog(true);
                    }}
                  >
                    + Add Blog
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {blogs?.data.map((blog) => (
                    <Box
                      sx={{
                        marginTop: "1rem",
                        width: "49.5%",
                        border: "1px solid rgba(0,0,0,0.5)",
                        padding: "1rem",
                        borderRadius: "4px",
                        position: "relative",
                      }}
                    >
                      <IconButton
                        sx={{ position: "absolute", top: 20, right: 20 }}
                        onClick={() => {
                          setDialogType("Edit");
                          setBlogId(blog._id);
                          setShowDialog(true);
                        }}
                      >
                        <AiOutlineEdit size={30} />
                      </IconButton>

                      <IconButton
                        sx={{ position: "absolute", top: 20, right: 70 }}
                        onClick={() => {
                          setShowAlert(true);
                          setBlogId(blog._id);
                        }}
                      >
                        <HiOutlineTrash size={30} />
                      </IconButton>

                      <Grid container spacing={3}>
                        {[
                          "_id",
                          "author",
                          "title",
                          "createdAt",
                          "updatedAt",
                          "minsToRead",
                          "thumbnail1Url",
                          "thumbnail2Url",
                        ].map((item) => (
                          <>
                            <Grid item xs={2}>
                              <Typography>{item}</Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography>
                                {item === "createdAt" ||
                                item === "updatedAt" ? (
                                  <Moment format="DD MMM YYYY, hh:mm a">
                                    {blog[item]}
                                  </Moment>
                                ) : (
                                  blog[item]
                                )}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            <Stack spacing={2} mt="1rem" alignItems="center">
              <Pagination
                count={10}
                variant="outlined"
                color="primary"
                size="large"
                sx={{
                  ul: {
                    li: {
                      button: {
                        fontSize: "18px",
                        fontFamily: `'Inter', sans-serif`,
                      },
                    },
                  },
                }}
              />
            </Stack>
          </Box>
        </Fade>

        <Fade in={tabValue === 1} unmountOnExit>
          <Box>
            {Object.keys(queries).length > 0 && (
              <Box sx={{ marginTop: "2rem" }}>
                <Box sx={{ display: "flex" }}>
                  <Typography fontSize="20px">
                    Total Queries: {queries.count}
                  </Typography>{" "}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  {queries?.data.map((query) => (
                    <Box
                      sx={{
                        marginTop: "1rem",
                        width: "49.5%",
                        border: "1px solid rgba(0,0,0,0.5)",
                        padding: "1rem",
                        borderRadius: "4px",
                        position: "relative",
                      }}
                    >
                      <IconButton
                        sx={{ position: "absolute", top: 20, right: 20 }}
                        onClick={() => {
                          setShowAlert(true);
                          setBlogId(query._id);
                        }}
                      >
                        <HiOutlineTrash size={30} />
                      </IconButton>

                      <Grid container spacing={3}>
                        {[
                          "_id",
                          "name",
                          "email",
                          "number",
                          "createdAt",
                          "userType",
                          "subject",
                          "message",
                        ].map((item) => (
                          <>
                            <Grid item xs={2}>
                              <Typography>{item}</Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography>
                                {item === "createdAt" ? (
                                  <Moment format="DD MMM YYYY, hh:mm a">
                                    {query[item]}
                                  </Moment>
                                ) : (
                                  query[item]
                                )}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Fade>
      </Box>

      <BlogDialog
        show={showDialog}
        setShow={setShowDialog}
        dialogType={dialogType}
        blogId={blogId}
      />

      <AlertDialog
        show={showAlert}
        setShow={setShowAlert}
        yesAction={handleDelete}
      />
    </Box>
  );
};

export default Admin;
