import React from 'react'
import { Box, Typography, Grid, Divider } from '@mui/material'
import img from '../learn-more/img.png'
import img2 from '../learn-more/img 2.png'
import bgImg2 from '../../assets/team2.png'
import icon from '../../assets/small_Icon.svg'
import taraju from '../../assets/taraju2.svg'
import love from '../../assets/love.svg'
import lock from '../../assets/lock.svg'
import sword from '../../assets/sword.svg'
import useBreakpoints from '../../hooks/useBreakpoints'

const Learn = [
  {
    id: 1,
    title: 'Introduction',
    text: [
      '  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      'amet massa vitae tortor Pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Id donec ultrices tincidunt arcu non sodales ne. Orci eu lobortis elementum nibh tellus molestie nunc non blandit. Sapien eget mi proin sed libero enim. A arcu cursus vitae congue mauris. llentesque massa placerat duis ultricies lacus sed turpis tincidunt. Orci ac auctor augue',
    ],
  },
  {
    id: '2',
    title: 'Ipsum',
    text: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    ],
  },
  {
    id: 3,
    title: 'Ipsum',
    text: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    ],
  },
]

const Section1 = () => {
  const { sm, md } = useBreakpoints()
  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          display: 'flex',
        }}
      >
        <img src={bgImg2} alt={img2} style={{ width: '100%' }} />
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '50%',
            margin: 'auto',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              fontSize: md ? '65px' : sm ? '50px' : '30px',
              fontWeight: 700,
            }}
          >
            Family Law
          </Typography>
          <img src={icon} alt={icon} style={{ width: '4.5rem' }} />
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{ mt: 2, mb: 5 }}
        flexDirection="column"
        margin="auto"
        width="70%"
      >
        <img src={img} alt="mai" width="100%" />
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={8}>
            <Grid item lg={9} md={12} sm={12}>
              {Learn.map((item) => (
                <Box>
                  <Box>
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontWeight={700}
                      fontSize="1.8rem"
                    >
                      {item.title}
                    </Typography>
                    {/* <Divider /> */}
                    <Grid container>
                      <Grid item xs={2}>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            backgroundColor: '#D0A144',
                            borderWidth: '1.5px',
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Divider
                          variant="fullWidth"
                          sx={{ backgroundColor: '#C4C4C4' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {item.text.map((text) => (
                    <Typography
                      sx={{ mt: 2 }}
                      variant="body2"
                      fontSize="medium"
                    >
                      {text}
                    </Typography>
                  ))}
                </Box>
              ))}

              <Box sx={{ mt: '5rem' }}>
                <img src={img2} alt={img2} width="100%" />
              </Box>
            </Grid>
            <Grid item lg={3} md={12} sm={12}>
              <Box
                sx={{
                  mt: '4rem',
                  backgroundColor: '#2E3642',
                  color: 'white',
                  paddingTop: '2rem',
                }}
              >
                <Typography
                  variant="body2"
                  textAlign="center"
                  fontSize="1.4rem"
                  fontWeight="800"
                >
                  Key Points
                </Typography>
                <Box>
                  <Box sx={{ mt: 4, ml: 2 }}>
                    <Box>
                      <img
                        src={taraju}
                        alt="taraju"
                        style={{ width: '2rem' }}
                      />
                    </Box>
                    <Typography
                      color="white"
                      variant="body2"
                      fontSize="1rem"
                      fontWeight={500}
                    >
                      Direct Way Of Justice{' '}
                    </Typography>
                    <Typography
                      color="#D7D7D7"
                      variant="subtitle2"
                      fontSize="0.6rem"
                      fontWeight={100}
                    >
                      amet massa vitae tortor. Pretium vulputate sapien nec
                      sagittis aliquam malesuada bibendu
                    </Typography>
                  </Box>
                  <Divider sx={{ backgroundColor: 'white', mt: 4 }} />
                </Box>
                <Box>
                  <Box sx={{ mt: 4, ml: 2 }}>
                    <Box>
                      <img src={lock} alt="taraju" style={{ width: '2rem' }} />
                    </Box>
                    <Typography
                      color="white"
                      variant="body2"
                      fontSize="1rem"
                      fontWeight={500}
                    >
                      Direct Way Of Justice{' '}
                    </Typography>
                    <Typography
                      color="#D7D7D7"
                      variant="subtitle2"
                      fontSize="0.6rem"
                      fontWeight={100}
                    >
                      amet massa vitae tortor. Pretium vulputate sapien nec
                      sagittis aliquam malesuada bibendu
                    </Typography>
                  </Box>
                  <Divider sx={{ backgroundColor: 'white', mt: 4 }} />
                </Box>
                <Box>
                  <Box sx={{ mt: 4, ml: 2 }}>
                    <Box>
                      <img src={love} alt="taraju" style={{ width: '2rem' }} />
                    </Box>
                    <Typography
                      color="white"
                      variant="body2"
                      fontSize="1rem"
                      fontWeight={500}
                    >
                      Direct Way Of Justice{' '}
                    </Typography>
                    <Typography
                      color="#D7D7D7"
                      variant="subtitle2"
                      fontSize="0.6rem"
                      fontWeight={100}
                    >
                      amet massa vitae tortor. Pretium vulputate sapien nec
                      sagittis aliquam malesuada bibendu
                    </Typography>
                  </Box>
                  <Divider sx={{ backgroundColor: 'white', mt: 4 }} />
                </Box>
                <Box>
                  <Box sx={{ mt: 4, ml: 2 }}>
                    <Box>
                      <img src={sword} alt="taraju" style={{ width: '2rem' }} />
                    </Box>
                    <Typography
                      color="white"
                      variant="body2"
                      fontSize="1rem"
                      fontWeight={500}
                    >
                      Direct Way Of Justice{' '}
                    </Typography>
                    <Typography
                      color="#D7D7D7"
                      variant="subtitle2"
                      fontSize="0.6rem"
                      fontWeight={100}
                    >
                      amet massa vitae tortor. Pretium vulputate sapien nec
                      sagittis aliquam malesuada bibendu
                    </Typography>
                  </Box>
                  <Divider sx={{ backgroundColor: 'white', mt: 4 }} />
                </Box>
                <Box></Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default Section1
