import { Box, ButtonBase, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";

import location from "../../assets/location.svg";
import envelope from "../../assets/envelope.svg";
import useBreakpoints from "../../hooks/useBreakpoints";

const Message = () => {
  const textFields = [
    {
      id: "name",
      title: "Full Name*",
    },
    {
      id: "email",
      title: "Email*",
    },
    {
      id: "phone",
      title: "Phone*",
    },
    {
      id: "message",
      title: "Message*",
    },
  ];

  const { xl, lg, md, sm } = useBreakpoints();
  const { enqueueSnackbar } = useSnackbar();

  const templateId = "template_t29u4gn";
  const serviceId = "service_8uj940j";
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  // const
  const sendEmail = async () => {
    try {
      await emailjs.send(serviceId, templateId, values);
      enqueueSnackbar("Enquiry Submitted SuccessFully!!", {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setValues({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      marginTop={sm ? "5rem" : "2rem"}
      marginBottom={md ? "10rem" : "5rem"}
      position="relative"
    >
      <Box sx={{ position: "relative", marginTop: 50 }}>
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "rgba(31, 39, 50, 0.75)",
            // top: sm ? 30 : 10,
            bottom: 30,
            right: 0,
            left: 0,
            margin: "auto",
            color: "#fff",
            width: "90%",
            // height: "80%",
            padding: md ? "4rem 6rem" : sm ? "2rem 3rem" : "1rem 1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            borderRadius: "10px",
          }}
        >
          <Typography fontSize={sm ? "35px" : "18px"} fontWeight={600}>
            The IEE model : Integrity, Empathy {sm && <br />}and Expertise.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: md ? "row" : "column",
          justifyContent: "space-between",
          marginTop: "4rem",
          paddingX: sm ? "5rem" : "1rem",
          position: "relative",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: lg ? "row" : md ? "column" : sm ? "row" : "column",
            gap: xl ? "5rem" : "2rem",
            width: lg ? "70%" : md ? "50%" : "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "1rem", maxWidth: "60%" }}>
            <Box>
              <img src={location} alt="Location icon" />
            </Box>

            <Box>
              <Typography size="18px" fontWeight={600}>
                Our Location
              </Typography>
              <Typography
                size="18px"
                fontWeight={400}
                color="#637381"
                marginTop="1rem"
              >
                Chamber No. 448,449 and 450 UCI Lawyers Chambers, {sm && <br />}{" "}
                Arera Hills, Bhopal. 462011.
              </Typography>
              <Typography
                size="18px"
                fontWeight={400}
                color="#637381"
                marginTop="1rem"
              >
                54, Daya Nagar, Yadav colony,Jabalpur, PIN code: 482002
              </Typography>
              <Typography
                size="18px"
                fontWeight={400}
                color="#637381"
                marginTop="1rem"
              >
                H-12, Lower Ground Floor, Green Park (Main) New Delhi – 110016
              </Typography>
              <Typography
                size="18px"
                fontWeight={400}
                color="#637381"
                marginTop="1rem"
              >
                H. No. 122, 12th Floor, Building 6-C, Seva Samiti CHS, Sion
                East, Antop Hill, Mumbai - 400037
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box>
              <img src={envelope} alt="Location icon" />
            </Box>

            <Box>
              <Typography size="18px" fontWeight={600}>
                How Can We Help?
              </Typography>

              <Typography
                size="18px"
                fontWeight={400}
                color="#637381"
                sx={{ textDecorationLine: "underline" }}
                marginTop="1rem"
              >
                pariharlaw@gmail.com
              </Typography>

              <Typography
                size="18px"
                fontWeight={400}
                color="#637381"
                sx={{ textDecorationLine: "underline" }}
              >
                advocate_parihar@yahoo.co.in
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: md ? "-15%" : "2rem",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "3rem 3rem",
            width: lg ? "30%" : md ? "40%" : "100%",
            boxShadow: "0px 4px 28px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Typography fontSize="28px" fontWeight={600}>
            Send us a Message
          </Typography>

          {textFields.map((item) => (
            <Box marginY="2rem" key={item.id}>
              <TextField
                variant="standard"
                label={item.title}
                fullWidth
                value={values[item.id]}
                onChange={(e) =>
                  setValues((prev) => ({ ...prev, [item.id]: e.target.value }))
                }
              />
            </Box>
          ))}

          <ButtonBase
            sx={{
              width: "100%",
              color: "#fff",
              paddingY: "1rem",
              borderRadius: "5px",
              marginTop: "2rem",
              backgroundColor: "#D0A144",
              "&:hover": {
                backgroundColor: "#D0A144",
              },
            }}
            onClick={sendEmail}
          >
            <Typography fontSize="16px" fontWeight={500}>
              Send
            </Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

export default Message;
