import { Box, ButtonBase, Typography } from "@mui/material";
import React from "react";
import "react-multi-carousel/lib/styles.css";

import lines_left from "../../assets/lines_left.svg";
import lines_right from "../../assets/lines_right.svg";
import logo from "../../assets/parihar_law_logo.svg";
import consultationIcon from "../../assets/consultation.svg";
import Home from "../home/Home page.webp";
import bannerIcon from "../../assets/bannerIcon.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  const { xl, lg, md, sm } = useBreakpoints();

  return (
    <Box marginBottom="5rem">
      <Box sx={{ position: "relative" }}>
        <Box>
          <img src={Home} alt="Lines" style={{ width: "100%" }} />
        </Box>

        <Box
          sx={{
            position: "absolute",
            backgroundColor: "rgba(31, 39, 50, 0.5)",
            top: 15,
            right: 0,
            left: 0,
            width: "90%",
            height: md ? "85%" : sm ? "80%" : "75%",
            margin: "auto",
            color: "#fff",
          }}
        >
          <Box
            sx={{
              width: lg ? "65%" : md ? "80%" : "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              paddingX: md ? "10rem" : sm ? "5rem" : "1rem",
            }}
          >
            <Box>
              <img src={bannerIcon} alt="Banner Icon" />
              <Typography
                variant="body2"
                fontSize={xl ? "65px" : md ? "50px" : sm ? "30px" : "20px"}
                fontWeight={700}
                lineHeight={md ? "70px" : "25px"}
              >
                We specialize in Winning
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={xl ? "22px" : md ? "18px" : sm ? "15px" : "10px"}
                marginTop={sm ? "1rem" : "0rem"}
                color="#CCAA60"
                fontWeight={400}
              >
                Under the leadership of Adv. A.P.S. Parihar, a luminary of civil
                law with more than 30 years of experience, and Adv. Udit Singh a
                legal prodigy, Parihar Law LLP takes winning cases very
                seriously.
              </Typography>
              <ButtonBase
                onClick={() => navigate("/enquiry")}
                sx={{
                  marginTop: md ? "2rem" : "0.5rem",
                  backgroundColor: "#D1B06B",
                  color: "#000",
                  padding: xl
                    ? "1rem 5rem"
                    : md
                    ? "0.5rem 2rem"
                    : "0.2rem 2rem",
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontSize={xl ? "22px" : md ? "18px" : sm ? "15px" : "10px"}
                  fontWeight={500}
                >
                  Get In Touch
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "3rem",
          paddingX: lg ? "8rem" : md ? "4rem" : "1rem",
          marginY: sm ? "5rem" : "2rem",
          width: "100%",
        }}
      >
        <Box width={md ? "30%" : "45%"}>
          <img src={lines_left} alt="Lines" style={{ width: "100%" }} />
          <Typography
            fontSize={sm ? "32px" : "15px"}
            fontWeight={600}
            marginTop={sm ? "3rem" : "1rem"}
            align={sm ? "left" : "center"}
          >
            Jurisdiction
          </Typography>
          <Typography
            fontSize={sm ? "14px" : "10px"}
            fontWeight={500}
            sx={{ opacity: 0.6, marginTop: "1rem" }}
            align={sm ? "left" : "center"}
          >
            In terms of territorial jurisdiction, we operate primarily in the
            cities of Bhopal, Jabalpur, Delhi and Mumbai. Online consultation
            and advice can be sought worldwide after registering for an
            appointment.
          </Typography>
        </Box>

        {md && (
          <Box width="30%">
            <img src={logo} alt="Logo" style={{ width: "100%" }} />
          </Box>
        )}

        <Box width={md ? "30%" : "45%"}>
          <img src={lines_right} alt="Lines" style={{ width: "100%" }} />
          <Typography
            fontSize={sm ? "32px" : "15px"}
            fontWeight={600}
            marginTop={sm ? "3rem" : "1rem"}
            align={sm ? "left" : "center"}
          >
            Work Style
          </Typography>
          <Typography
            fontSize={sm ? "14px" : "10px"}
            fontWeight={500}
            sx={{ opacity: 0.6, marginTop: "1rem" }}
            align={sm ? "left" : "center"}
          >
            We protect our Heritage, by protecting our Integrity. We are rooted
            in a very deep understanding of the law and a profound feeling of
            Empathy. Show us your BPL Card, and we contest your case for Free.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#D1B06B",
            padding: lg
              ? "1.5rem 3rem"
              : sm
              ? "1.2rem 2.5rem"
              : "0.5rem 1.2rem",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#1F2732",
              borderRadius: "4px",
              padding: "4px 12px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#fff", fontSize: lg ? "26px" : "20px" }}
            >
              30+
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            fontWeight={500}
            fontSize={lg ? "22px" : sm ? "18px" : "12px"}
          >
            Years
            <br />
            Experience
          </Typography>
        </Box>
        <ButtonBase onClick={() => navigate("/enquiry")}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#3B434E",
              padding: lg
                ? "1.5rem 3rem"
                : sm
                ? "1.2rem 2.5rem"
                : "0.5rem 1.2rem",
              gap: "1rem",
            }}
          >
            <img src={consultationIcon} alt="Lines" />

            <Typography
              color="#fff"
              variant="subtitle2"
              fontWeight={500}
              fontSize={lg ? "22px" : sm ? "18px" : "12px"}
            >
              Book an online <br /> consultation
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default Banner;
