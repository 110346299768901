import axios from "axios";
import React, { createContext, useState } from "react";

export const Context = createContext();

const ContextProvider = ({ children }) => {
  // credentials
  const admin_user = "admin";
  const admin_password = "admin";

  // bas url
  const base_url = "https://easy-blue-hare-wear.cyclic.app";
  // const base_url = 'http://localhost:5000'

  // states
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [blogs, setBlogs] = useState({});
  const [currentBlog, setCurrentBlog] = useState({});
  const [queries, setQueries] = useState({});

  function login(user, password) {
    setLoading(true);

    if (user !== admin_user) {
      setLoading(false);
      return {
        success: false,
        msg: "User not found",
      };
    }

    if (password !== admin_password) {
      setLoading(false);
      return {
        success: false,
        msg: "Incorrect password",
      };
    }

    setIsLoggedIn(true);
    window.localStorage.setItem("login-status", true);
    setLoading(false);

    return {
      success: true,
      msg: "Logged in successfully",
    };
  }

  async function getBlogs() {
    setLoading(true);
    const res = await axios.get(base_url + "/blog");
    setBlogs(res.data);
    setLoading(false);
  }

  async function addBlog(payload) {
    setLoading(true);
    const res = await axios.post(base_url + "/blog", payload);
    console.log(res);
    setLoading(false);
    return res.data;
  }

  async function updateBlogMedia(id, file) {
    setLoading(true);
    let formData = new FormData();
    formData.append("docxFile", file);
    const res = await axios.put(base_url + "/blog/parse-blog/" + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Update Res", res);
    setLoading(false);
  }

  async function updateBlog(id, payload) {
    setLoading(true);
    const res = await axios.put(base_url + "/blog/" + id, payload);

    console.log("Update Res", res);
    setLoading(false);
    return res.data;
  }

  async function getSingleBlog(id) {
    setLoading(true);
    await getBlogs();

    const temp = blogs?.data.find((blog) => blog._id === id);

    setCurrentBlog(temp);
    setLoading(false);
  }

  async function deleteBlog(id) {
    setLoading(true);
    const res = await axios.delete(base_url + "/blog/" + id);
    console.log(res);
    setLoading(false);
  }

  async function getQueries() {
    setLoading(true);
    const res = await axios.get(base_url + "/query");
    console.log(res.data.data);
    setQueries(res.data);
    setLoading(false);
  }

  return (
    <Context.Provider
      value={{
        login,
        isLoggedIn,
        loading,
        getBlogs,
        blogs,
        addBlog,
        updateBlog,
        getSingleBlog,
        currentBlog,
        setCurrentBlog,
        deleteBlog,
        getQueries,
        queries,
        updateBlogMedia,
        isFirstLoad,
        setIsFirstLoad,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
