import React, { useContext, useState, useEffect } from "react";
import { Context } from "../context/Context";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const navigate = useNavigate();

  const { login, isLoggedIn } = useContext(Context);

  const [values, setValues] = useState({
    user: "",
    password: "",
  });
  const [snack, setSnack] = useState({
    show: false,
    severity: "",
    msg: "",
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/admin");
    }
  }, []);

  function closeSnack() {
    setSnack({
      show: false,
      severity: "",
      msg: "",
    });
  }

  async function handleSubmit() {
    const res = await login(values.user, values.password);

    if (!res.success) {
      setSnack({
        show: true,
        severity: "error",
        msg: res.msg,
      });

      return;
    }

    setSnack({
      show: true,
      severity: "success",
      msg: res.msg,
    });

    navigate("/admin");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout header={false}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          marginX: "auto",
          marginBottom: "10rem",
        }}
      >
        <Typography
          variant="body2"
          fontSize="65px"
          fontWeight={700}
          align="center"
        >
          Admin Login
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "25%",
            margin: " 5rem auto",
            gap: 2,
          }}
        >
          <TextField
            variant="outlined"
            label="User"
            value={values.user}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, user: e.target.value }))
            }
          />
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            value={values.password}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, password: e.target.value }))
            }
          />

          <Button variant="outlined" onClick={handleSubmit}>
            <Typography variant="subtitle2" fontSize="20px" fontWeight={500}>
              Login
            </Typography>
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snack.show}
        onClose={closeSnack}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnack}
          severity={snack.severity}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {snack.msg}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default AdminLogin;
