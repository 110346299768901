import React, { useState } from "react";
import {
  AppBar,
  Box,
  Typography,
  Button,
  IconButton,
  useScrollTrigger,
  Slide,
} from "@mui/material";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../assets/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";
import AppDrawer from "../Drawer/AppDrawer";
import KnowYourRightsMenu from "../Menu/KnowYourRightsMenu";

const Appbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const headerLinks = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Know your Rights",
      route: "/know-your-rights",
      dropdown: true,
    },
    {
      title: "Educate Yourself",
      route: "/educate-yourself",
    },
    {
      title: "Blogs",
      route: "/blogs",
    },
    {
      title: "Our Team",
      route: "/our-team",
    },
    {
      title: "Star Associates",
      route: "/star-associate",
    },
    {
      title: "Gallery",
      route: "/gallery",
    },
    {
      title: "Work with us",
      route: "/work-with-us",
    },
  ];

  const { xl, lg, md, sm } = useBreakpoints();
  const [showDrawer, setShowDrawer] = useState(false);

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  return (
    <>
      <HideOnScroll>
        <AppBar>
          <Box
            style={{
              display: "flex",
              backgroundColor: "#D0A144",
              height: "50px",
              left: "0px",
              justifyContent: "flex-end",
              position: "relative",
            }}
          >
            {md ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "0rem 4rem",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                  position="relative"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <HiOutlineMail size="1.5rem" color="white" />
                    <Typography sx={{ ml: "4px", color: "white" }}>
                      pariharlaw@gmail.com
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      position: lg ? "absolute" : "",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      left: 0,
                      margin: "auto",
                      height: "fit-content",
                      width: "fit-content",
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "1.5rem",
                    }}
                  >
                    धर्मो रक्षति रक्षितः
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ ml: "40px" }}
                  >
                    <BsTelephone size="1.05rem" color="white" />
                    <Typography sx={{ ml: "4px", color: "white" }}>
                      +91 9574486593,+91 9425675449, 0755-4933741
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingX: "1rem",
                }}
              >
                <IconButton onClick={() => setShowDrawer(true)}>
                  <GiHamburgerMenu color="#fff" />
                </IconButton>
                <Typography
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    margin: "auto",
                    height: "fit-content",
                    width: "fit-content",
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: md ? "1rem" : "1.5rem",
                  }}
                >
                  धर्मो रक्षति रक्षितः
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              backgroundColor: "#1F2732",
              paddingX: "1rem",
              height: xl ? "110px" : md ? "90px" : sm ? "90px" : "60px",
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                width: md ? "10%" : sm ? "15%" : "20%",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            />
            {md && (
              <Box
                color="#B99A51"
                display="flex"
                alignItems="space-between"
                gap={lg ? "1.5rem" : "1rem"}
                fontWeight="bold"
              >
                {headerLinks.map((item) =>
                  item.dropdown ? (
                    <KnowYourRightsMenu item={item} location={location} />
                  ) : (
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      key={item.id}
                    >
                      <Typography
                        key={item.route}
                        sx={{
                          cursor: "pointer",
                          display: "inline-block",
                          position: "relative",
                          userSelect: "none",

                          "&:after": {
                            content: "''",
                            position: "absolute",
                            width: "100%",
                            transform: `scaleX(${
                              item.route === location.pathname ? "1" : "0"
                            })`,
                            height: "2px",
                            bottom: 0,
                            left: 0,
                            backgroundColor: "#B89A51",
                            transformOrigin: "bottom right",
                            transition: "transform 0.25s ease-out",
                          },

                          "&:hover": {
                            "&:after": {
                              transform: "scaleX(1)",
                              transformOrigin: "bottom left",
                            },
                          },
                        }}
                        variant="subtitle2"
                        fontSize={xl ? "22px" : lg ? "18px" : "15px"}
                        fontWeight={400}
                        onClick={(e) => navigate(item.route)}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            )}

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#B99A51",
                  color: "white",
                  "&:hover": { backgroundColor: "#f4d194", color: "black" },
                }}
                onClick={() => navigate("/enquiry")}
              >
                Enquiry
              </Button>
            </Box>
          </Box>
        </AppBar>
      </HideOnScroll>

      <AppDrawer show={showDrawer} setShow={setShowDrawer} />
    </>
  );
};

export default Appbar;
