import React, { useEffect } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import Layout from "../components/Layout";
import knowYourRights from "../sections/home/Home page.webp";
import expertise_icon from "../assets/expertise_icon.svg";
import { GoChevronRight } from "react-icons/go";
// import womenTaraju from '../assets/women_taraju.png'
import useBreakpoints from "../hooks/useBreakpoints";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import data from "../data/LegalPracticeAreas.json";

const LegalPracticeAreas = () => {
  const { md, sm } = useBreakpoints();

  const { type } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Box sx={{ position: "relative" }}>
        <img
          src={knowYourRights}
          alt="Legal Practice Areas"
          style={{ width: "100%" }}
        />

        <Box
          sx={{
            position: "absolute",
            // height: "fit-content",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // height: '100%',
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <Typography
            variant="body2"
            fontSize={md ? "65px" : sm ? "50px" : "30px"}
            fontWeight={700}
            color="#fff"
            sx={{
              textShadow: "0px 2px 2px rgba(0,0,0,0.46)",
              textAlign: "center",
            }}
          >
            {data[type].heading}
          </Typography>
          <img src={expertise_icon} alt="icon" />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: sm ? "row" : "column",
          padding: md ? "5rem" : "2rem",
        }}
      >
        <Box sx={{ width: sm ? "22%" : "100%" }}>
          <Typography fontSize={md ? "18px" : "15px"} fontWeight={700}>
            Table of Content
          </Typography>

          <Box marginTop="1.5rem" display="flex" flexDirection="column" gap={1}>
            {data[type].sections.map((item) => (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* <GoChevronRight size={24} color="#696984" /> */}
                  <Typography
                    key={item.id}
                    fontSize={md ? "18px" : "15px"}
                    fontWeight={400}
                    color="#3366CC"
                    sx={{
                      userSelect: "none",
                      cursor: "pointer",
                      "&:hover": {
                        textDecorationLine: "underline",
                        fontWeight: 500,
                      },
                    }}
                    onClick={() => {
                      const element = document.getElementById(item.heading);
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    {item.heading}
                  </Typography>
                </Box>
                {item.content.map((c) =>
                  c.heading ? (
                    <Typography
                      fontSize={md ? "15px" : "12px"}
                      fontWeight={400}
                      color="#3366CC"
                      sx={{
                        marginLeft: "1rem",
                        userSelect: "none",
                        cursor: "pointer",
                        "&:hover": {
                          textDecorationLine: "underline",
                          fontWeight: 500,
                        },
                      }}
                      onClick={() => {
                        const element = document.getElementById(c.heading);
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      {c.heading}
                    </Typography>
                  ) : null
                )}
              </>
            ))}
          </Box>
        </Box>

        <Box
          sx={{ width: sm ? "75%" : "100%", marginTop: sm ? "0rem" : "4rem" }}
        >
          {data[type].sections.map((item) => (
            <Box sx={{ marginTop: "2rem" }}>
              <Typography
                variant="body2"
                fontSize={md ? "45px" : "35px"}
                fontWeight={700}
                id={item.heading}
              >
                {item.heading}
              </Typography>

              <Grid container>
                <Grid item xs={2}>
                  <Divider
                    variant="fullWidth"
                    sx={{
                      backgroundColor: "#D0A144",
                      borderWidth: "1.5px",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Divider
                    variant="fullWidth"
                    sx={{ backgroundColor: "#C4C4C4" }}
                  />
                </Grid>
              </Grid>

              {item.content.map((kit) => (
                <>
                  {kit.heading && (
                    <Typography
                      id={kit.heading}
                      variant="subtitle2"
                      fontSize={md ? "25px" : "15px"}
                      marginTop="1rem"
                    >
                      {kit.heading}
                    </Typography>
                  )}
                  {kit.para
                    ? kit.para.split("\n").map((p) => (
                        <Typography
                          variant="subtitle2"
                          fontSize={md ? "20px" : "17px"}
                          fontWeight={400}
                          marginTop={kit.heading ? "0.5rem" : "1rem"}
                        >
                          {p}
                        </Typography>
                      ))
                    : null}
                </>
              ))}
            </Box>
          ))}

          {/* <Box>
            <Typography
              variant="body2"
              fontSize={md ? '45px' : '35px'}
              fontWeight={700}
            >
              Introduction
            </Typography>

            <Grid container>
              <Grid item xs={2}>
                <Divider
                  variant="fullWidth"
                  sx={{
                    backgroundColor: '#D0A144',
                    borderWidth: '1.5px',
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Divider
                  variant="fullWidth"
                  sx={{ backgroundColor: '#C4C4C4' }}
                />
              </Grid>
            </Grid>

            <Typography
              variant="subtitle2"
              fontSize={md ? '20px' : '17px'}
              fontWeight={400}
              marginTop="1rem"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>

            <Typography
              variant="subtitle2"
              fontSize={md ? '20px' : '17px'}
              fontWeight={400}
              marginTop="1rem"
            >
              amet massa vitae tortor. Pretium vulputate sapien nec sagittis
              aliquam malesuada bibendum. Id donec ultrices tincidunt arcu non
              sodales ne. Orci eu lobortis elementum nibh tellus molestie nunc
              non blandit. Sapien eget mi proin sed libero enim. A arcu cursus
              vitae congue mauris. llentesque massa placerat duis ultricies
              lacus sed turpis tincidunt. Orci ac auctor augue
            </Typography>
          </Box>

          <Box marginTop="2rem">
            <Typography
              variant="body2"
              fontSize={md ? '45px' : '35px'}
              fontWeight={700}
            >
              History
            </Typography>

            <Grid container>
              <Grid item xs={2}>
                <Divider
                  variant="fullWidth"
                  sx={{
                    backgroundColor: '#D0A144',
                    borderWidth: '1.5px',
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Divider
                  variant="fullWidth"
                  sx={{ backgroundColor: '#C4C4C4' }}
                />
              </Grid>
            </Grid>

            <Typography
              variant="subtitle2"
              fontSize={md ? '20px' : '17px'}
              fontWeight={400}
              marginTop="1rem"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>

            <Typography
              variant="subtitle2"
              fontSize={md ? '20px' : '17px'}
              fontWeight={400}
              marginTop="1rem"
            >
              Dui accumsan sit amet nulla. Auctor elit sed vulputate mi sit.
              Libero nunc consequat interdum varius sit amet. Sem nulla pharetra
              diam sit. Eget dolor morbi non arcu. In massa tempor nec feugiat.
              Eu facilisis sed odio morbi quis commodo. In ante metus dictum at
              tempor commodo ullamcorper. Diam donec adipiscing tristique risus
              nec feugiat in fermentum posuere. Vel pretium lectus quam id leo
              in. Massa tincidunt dui ut ornare lectus sit. Ut porttitor leo a
              diam sollicitudin tempor id eu nisl. Id diam maecenas ultricies mi
              eget. Sed pulvinar proin gravida hendrerit lectus a.
            </Typography>
          </Box>
          <Box marginTop="2rem">
            <Typography
              variant="body2"
              fontSize={md ? '45px' : '35px'}
              fontWeight={700}
            >
              Introduction
            </Typography>

            <Grid container>
              <Grid item xs={2}>
                <Divider
                  variant="fullWidth"
                  sx={{
                    backgroundColor: '#D0A144',
                    borderWidth: '1.5px',
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Divider
                  variant="fullWidth"
                  sx={{ backgroundColor: '#C4C4C4' }}
                />
              </Grid>
            </Grid>

            <Typography
              variant="subtitle2"
              fontSize={md ? '20px' : '17px'}
              fontWeight={400}
              marginTop="1rem"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Typography>

            <Typography
              variant="subtitle2"
              fontSize={md ? '20px' : '17px'}
              fontWeight={400}
              marginTop="1rem"
            >
              Dui accumsan sit amet nulla. Auctor elit sed vulputate mi sit.
              Libero nunc consequat interdum varius sit amet. Sem nulla pharetra
              diam sit. Eget dolor morbi non arcu. In massa tempor nec feugiat.
              Eu facilisis sed odio morbi quis commodo. In ante metus dictum at
              tempor commodo ullamcorper. Diam donec adipiscing tristique risus
              nec feugiat in fermentum posuere. Vel pretium lectus quam id leo
              in. Massa tincidunt dui ut ornare lectus sit. Ut porttitor leo a
              diam sollicitudin tempor id eu nisl. Id diam maecenas ultricies mi
              eget. Sed pulvinar proin gravida hendrerit lectus a.
            </Typography>
          </Box>
        </Box> */}
        </Box>
      </Box>
    </Layout>
  );
};

export default LegalPracticeAreas;
