import React from "react";
import { Box, Typography, Button } from "@mui/material";
import img from "../../assets/small_Icon.svg";
import { useNavigate } from "react-router-dom";

const Section1 = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      marginBottom="10rem"
    >
      <Typography fontSize="10rem" variant="subtitle2" color="#D1B06B">
        404{" "}
      </Typography>
      <img src={img} alt="404" />

      <Typography
        variant="body2"
        fontWeight={900}
        fontSize="1.875rem"
        color="#637381"
        sx={{ mt: 2 }}
      >
        Page Not Found
      </Typography>
      <Typography variant="subtitle2" color="#637381" fontSize="0.8rem">
        The page you are looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#B99A51",
          color: "white",
          "&:hover": { backgroundColor: "#f4d194", color: "black" },
          mt: 3,
        }}
        onClick={() => navigate("/")}
      >
        Back to Home
      </Button>
    </Box>
  );
};

export default Section1;
