import React from "react";
import { Box, Typography, Grid, Card, CardMedia } from "@mui/material";
import icon from "../../assets/small_Icon.svg";
import img2 from "../../assets/team1.jpg";
import ourTeam from "../../assets/our_team.webp";
import Akhilesh from "../team/Akhilesh Bajpai.jpg";
import Ashok from "../team/Ashok.jpg";
import Udit from "../team/Udit.jpg";
import Amar2 from "../team/Amar Nagraj 2.jpg";
import APS from "../team/APS Parihar.webp";
import Rohit from "../team/Rohit Sharma .jpg";
import Sanidhya from "../team/Sanidhya Jain.webp";
import AnupamaSingh from "../team/Anupama Singh.webp";
import UpendraSinghTomar from "../team/Upendra Singh Tomar.webp";
import NayanDubey from "../team/Nayan Dubey.webp";
import SarthakDoshi from "../team/Sarthak Doshi.webp";
import ShardulChouhan from "../team/Shardul Chouhan.webp";

import sujata_prasant from "../team/sujata_prasant.jpeg";
import nagwanshi from "../team/nagwanshi.jpeg";
import ayushi_sharma from "../team/ayushi_sharma.jpeg";

import Logo from "../../assets/logo.svg";
import useBreakpoints from "../../hooks/useBreakpoints";

const Section1 = () => {
  const Team = [
    {
      id: 1,
      name: "	Adv. A.P.S. Parihar",
      text: "Adv. Arun Pratap Singh Parihar, walking on the footsteps of Late Adv. G.K. Shrivastava, got enrolled in the Bar council of Madhya Pradesh in the year 1993 and has been working tirelessly ever since. is a master of negotiation and dispute resolution. They possess a deep understanding of contract law, property rights, and civil procedure. He adopts meticulous research and analysis to build strong cases, coupled with clear communication to explain legalities to clients. Adept at crafting persuasive arguments and negotiating settlements, he strives for efficient resolutions while fiercely advocating for his clients' rights in court when necessary. He strives for fair settlements but zealously advocates for his client's rights in court while adhering to professional decorum and civility towards opposing counsel.",
      position: "",
      img: APS,
    },
    {
      id: 2,
      name: "Adv. Udit Singh",
      text: "Adv. Udit Singh was born and brought up in a lawyer family and thus has a clear knack on the subjects of law. He got enrolled in the Bar council of Madhya Pradesh in the year 2019 and has worked wonders since then, having argued and won cases before the District Courts as well as the High Court of M.P. and Supreme Court of India. He is a skilled communicator with a deep understanding of Indian law. He can analyze complex legal issues, craft persuasive arguments, and navigate courtroom procedures. Ethical and empathetic, he prioritizes client needs while upholding the law and adhering to professional codes.",
      position: "",
      img: Udit,
    },
    {
      id: 3,
      name: "Adv. Anupama Singh",
      text: "Adv. Anupama Singh is a dedicated civil lawyer with over 15 years of experience specializing in personal injury, contract disputes, and family law. Known for her meticulous attention to detail and compassionate client advocacy, Anupama consistently achieves favorable outcomes through strategic negotiation and litigation. Her commitment to justice drives her exceptional legal practice.",
      position: "",
      img: AnupamaSingh,
    },
    {
      id: 4,
      name: "Adv. Upendra Singh Tomar",
      text: "Adv. Upendra Singh Tomar  is a proficient civil lawyer with a decade of experience in personal injury, real estate, and contract law. Known for his strategic approach and dedication to clients, Tomar achieves impressive results in both negotiation and litigation. His commitment to justice and meticulous attention to detail set him apart in the legal field.",
      position: "",
      img: UpendraSinghTomar,
    },
    {
      id: 5,
      name: "Adv. Akhilesh Bajpai",
      text: "Adv. Akhilesh Bajpai is an accomplished civil lawyer with over a decade of experience in personal injury, employment law, and contract disputes. Renowned for his strategic acumen and empathetic client approach, Bajpai excels in securing favorable outcomes through both negotiation and litigation. His dedication to justice and client advocacy defines his legal career.",
      position: "",
      img: Akhilesh,
    },
    {
      id: 6,
      name: "Adv. Amar Nagraj",
      text: "Adv. Amar Nagraj is is a seasoned civil lawyer with 12 years of expertise in personal injury, family law, and business disputes. Known for his strategic thinking and client-focused approach, Nagraj consistently delivers favorable results. His dedication to justice and unwavering commitment to clients set him apart in the legal field.",
      position: "",
      img: Amar2,
    },
    {
      id: 7,
      name: "Adv. Raghuveer Sharma",
      text: "Adv. Raghuveer Sharma is a skilled civil lawyer with over 10 years of experience in personal injury, real estate, and employment law. Renowned for his thorough preparation and client-first approach, Sharma achieves strong results in negotiations and court. His commitment to fairness and justice defines his distinguished legal practice.",
      position: "",
      img: Logo,
    },
    {
      id: 8,
      name: "Adv. Nayan Dubey",
      text: "Adv. Nayan dubey is an experienced civil lawyer with over 7 years of expertise in personal injury, family law, and business litigation. Known for his strategic thinking and compassionate client advocacy, Dubey consistently secures favorable outcomes. His commitment to justice and meticulous attention to details highlights distinguished legal career.",
      position: "",
      img: NayanDubey,
    },
    {
      id: 9,
      name: "Adv. Sarthak Doshi",
      text: "Adv. Sarthak Doshi is a veteran civil lawyer with 15 years of expertise in personal injury, business disputes, and gaming laws. He works with Parihar Law LLP as a consultant on case to case basis. Renowned for his strategic acumen and client-focused approach, Doshi consistently secures favorable outcomes. His dedication to justice and meticulous preparation underscores a distinguished career in civil law.",
      position: "",
      img: SarthakDoshi,
    },
    {
      id: 10,
      name: "Adv. Shardul Chouhan",
      text: "Adv. Shardul Chauhan Though enrolled in the Bar council of Madhya Pradesh in November 2022, his career began with a fascination for the intricate world of electronics engineering while completing his B.Tech. However, as he delved deeper, he witnessed a gap between technological innovation and its responsible implementation. This sparked a desire to bridge that gap, leading him to pursue an MBA and ultimately, a career in advocacy. He strives to leverage his combined skillset to champion responsible technological advancements that benefit society.",
      position: "",
      img: ShardulChouhan,
    },
    {
      id: 11,
      name: "Adv. Rohit Sharma",
      text: "Adv. Rohit Sharma is a highly experienced civil lawyer specializing in personal injury, contract law, and family disputes. With a reputation for strategic expertise and a client-centered approach, Sharma consistently achieves successful outcomes. His dedication to justice and meticulous attention to detail make him a standout in the legal community.",
      position: "",
      img: Rohit,
    },
    {
      id: 12,
      name: "Mr. Sanidhya Jain",
      text: "Adv. Sanidhya Jain is a summer associate with extensive experience in, environmental law, personal injury, employment disputes, and family law. Known for his love and advocacy for Animal Rights, Jain excels in achieving favorable results through negotiation. His unwavering commitment to justice and client advocacy defines her legal practice.",
      position: "",
      img: Sanidhya,
    },
    {
      id: 13,
      name: "Mr. Ashok Sahu",
      text: "Adv. Ashok Sahu, being the office stenographer, is a skilled professional who transcribes spoken words into written text quickly and accurately. Using shorthand techniques or stenotype machines, he can capture verbatim records of legal proceedings, meetings, and other events. His expertise ensures precise documentation, essential for legal, medical, and business purposes.",
      position: "",
      img: Ashok,
    },
    {
      id: 14,
      name: "Adv. Sujata Prasant ",
      text: "Adv. Sujata Prasant is a distinguished lawyer known for her expertise in corporate law and litigation. With a sharp analytical mind and a compassionate approach, she excels in navigating complex legal challenges. Sujata’s dedication to her clients and her commitment to justice make her a respected figure in the legal community, consistently delivering exceptional results.",
      position: "",
      img: sujata_prasant,
    },
    {
      id: 15,
      name: "Adv. Ashish Nagwanshi",
      text: "Adv. Ashish Nagwanshi is a highly skilled lawyer and an acting office assistant. Renowned for his strategic acumen and meticulous attention to detail, Ashish delivers effective legal solutions with a client-centric approach. His unwavering dedication and expertise make him a trusted advocate in the legal field, ensuring exceptional representation and favorable outcomes for his clients.",
      position: "",
      img: nagwanshi,
    },
    {
      id: 16,
      name: "Adv. Ayushi Sharma",
      text: "Adv. Ayushi Sharma is a dedicated civil lawyer with a strong focus on resolving complex legal disputes. She brings a wealth of knowledge and expertise to her practice, advocating passionately for her clients' rights in civil matters. With a keen eye for detail and a commitment to achieving fair outcomes, Ayushi is a trusted advisor at our Firm.",
      position: "",
      img: ayushi_sharma,
    },
  ];

  const { sm, md, lg } = useBreakpoints();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <img src={ourTeam} alt="group" style={{ width: "100%" }} />
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            margin: "auto",
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "white",
              fontSize: md ? "65px" : sm ? "50px" : "30px",
              fontWeight: 700,
              textShadow: "0px 2px 2px rgba(0,0,0,0.46)",
            }}
          >
            Team
          </Typography>
          <img src={icon} alt={icon} style={{ width: "4.5rem" }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: lg ? "5rem 8rem" : sm ? "5rem 4rem" : "3rem 3rem",
        }}
      >
        <Typography
          variant="body2"
          fontWeight={700}
          sx={{ mb: md ? "5rem" : "1rem" }}
          fontSize={sm ? "45px" : "35px"}
        >
          Meet Our Attorneys
        </Typography>
        {Team.map((item) => (
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: !md && "center",
                marginTop: "2rem",
              }}
              md={5}
              xs={12}
            >
              <Box
                sx={{
                  width: "341px",
                  height: "431px",
                  border: "1px solid #D1B06B",
                  // overflow: "hidden",
                }}
              >
                <img
                  src={item.img}
                  alt={item.name}
                  style={{
                    width: "100%",
                    height: "90%",
                    objectFit: "cover",
                    objectPosition: "top",
                    // margin: 10,
                  }}
                />
                <Typography
                  fontSize="20px"
                  gutterBottom
                  variant="body2"
                  fontWeight={700}
                  textAlign="center"
                  marginTop="auto"
                >
                  {item.name}
                </Typography>

                {/* <Card
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    marginLeft: "50px",
                    marginBottom: "auto",
                    marginTop: "auto",
                    width: "100%",
                    height: "80%",
                    boxShadow: "5px",
                  }}
                >
                  <CardMedia component="img" image={item.img} />

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ mt: 1 }}
                  >
                    <Typography
                      fontSize="20px"
                      gutterBottom
                      variant="body2"
                      fontWeight={700}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </Card> */}
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Box>
                <Typography
                  sx={{ mb: 3, mt: 8 }}
                  fontSize="20px"
                  fontWeight={500}
                  color="#555555"
                  variant="subtitle2"
                >
                  {item.text}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
      <img src={img2} alt="group" style={{ width: "100%" }} />
    </>
  );
};

export default Section1;
