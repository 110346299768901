import React from "react";
import { Typography, Box, Divider, Grid } from "@mui/material";
import left from "../../assets/lines_left.svg";
import right from "../../assets/lines_right.svg";
import logo from "../../assets/logo.svg";
import { AiOutlineInstagram } from "react-icons/ai";
import { MdFacebook } from "react-icons/md";
import { AiOutlineTwitter } from "react-icons/ai";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const links = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Know your Rights",
      route: "/know-your-rights/consumer",
      dropdown: true,
    },
    {
      title: "Educate Yourself",
      route: "/educate-yourself",
    },
    {
      title: "Blogs",
      route: "/blogs",
    },
    {
      title: "Our Team",
      route: "/our-team",
    },
    {
      title: "Star Associates",
      route: "/star-associate",
    },
    {
      title: "Gallery",
      route: "/gallery",
    },
    {
      title: "Work with us",
      route: "/work-with-us",
    },
  ];

  const { lg, md } = useBreakpoints();

  function openInNewTab(url) {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      alert("Please allow popups for this website");
    }
  }

  return (
    <Box
      sx={{ backgroundColor: "#1F2732", padding: "3rem 0rem" }}
      color="white"
    >
      <Box display="flex" justifyContent="center" gap={3} sx={{ mb: "4rem" }}>
        <img src={left} alt={left} style={{ width: "20%" }} />
        <img src={logo} alt={right} />
        <img src={right} alt={logo} style={{ width: "20%" }} />
      </Box>
      <Box
        width={lg ? "70%" : "90%"}
        margin="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            userSelect: "none",
            flexWrap: "wrap",
          }}
        >
          {links.map((item) => (
            <Typography
              variant="subtitle2"
              sx={{
                cursor: "pointer",
                ":hover": {
                  textDecorationLine: "underline",
                },
              }}
              onClick={() => navigate(item.route)}
            >
              {item.title}
            </Typography>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 5,
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: "bold", marginRight: 2 }}>
            Contact Us:
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: md ? "row" : "column",
              flex: 1,
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <a
              href={`mailto:pariharlaw@gmail.com`}
              style={{ color: "white", textDecorationLine: "none" }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                  ":hover": {
                    textDecorationLine: "underline",
                  },
                }}
              >
                pariharlaw@gmail.com
              </Typography>
            </a>
            <a
              href={`mailto:advocate_parihar@yahoo.co.in`}
              style={{ color: "white", textDecorationLine: "none" }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                  ":hover": {
                    textDecorationLine: "underline",
                  },
                }}
              >
                advocate_parihar@yahoo.co.in
              </Typography>
            </a>
            <Typography
              sx={{
                cursor: "pointer",
                ":hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              +91 9574486593
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                ":hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              +91 9425675449
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                ":hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              0755-4933741
            </Typography>
          </Box>
        </Box>

        <Box>
          <Divider sx={{ backgroundColor: "white", mt: 5 }} />
          <Box>
            <Grid container sx={{ mt: 3, mb: 3 }}>
              <Grid item xs={5}>
                <Typography variant="subtitle2">Copyright@2024</Typography>
              </Grid>
              <Grid item xs={7}>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="subtitle2" color="#D0A144">
                    Follow :
                  </Typography>

                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      openInNewTab("https://www.instagram.com/pariharlaw/")
                    }
                  >
                    <AiOutlineInstagram size={24} />
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      openInNewTab("https://www.facebook.com/pariharlaw")
                    }
                  >
                    <MdFacebook size={24} />
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => openInNewTab("https://x.com/pariharlaw")}
                  >
                    <AiOutlineTwitter size={24} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ backgroundColor: "white" }} />
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
