import { Box, Button, Dialog, Typography } from "@mui/material";
import useBreakpoints from "../../hooks/useBreakpoints";

const DisclaimerDialog = ({ show, setShow }) => {
  const closeDialog = () => {
    setShow(false);
  };

  const { md } = useBreakpoints();

  return (
    <Dialog open={show} fullWidth={md} fullScreen={!md} maxWidth="sm">
      <Box sx={{ p: md ? 5 : 1, mt: md ? 0 : 5 }}>
        <Typography
          textAlign="center"
          fontSize={md ? "24px" : "20px"}
          fontWeight={500}
        >
          DISCLAIMER
        </Typography>
        <Typography
          fontSize={md ? "16px" : "12px"}
          fontWeight={400}
          textAlign="center"
        >
          The Bar Council of India expressly bars advertisement or solicitation
          by advocates in any form or manner, as enumerated in Rule 36 of the
          Rules on Standards of professional (Chapter II of Part VI of BCI
          Rules). By accessing this website, www.pariharlaw.com, you acknowledge
          and confirm that you are seeking information relating to Parihar Law
          LLP of your own accord and that there has been no form of
          solicitation, advertisement or inducement by Parihar Law LLP or its
          members. The material available on this website is for informational
          purposes only and should not be interpreted as soliciting or
          advertisement. No information provided on this website should be
          construed as legal advice. The contents of this website are the
          intellectual property of Parihar Law LLP. 
        </Typography>

        <Box textAlign="center" mt={4}>
          <Button variant="contained" onClick={closeDialog}>
            <Typography>Confirm</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DisclaimerDialog;
