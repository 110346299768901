import React from "react";
import { Box, Typography, Grid, Divider, ButtonBase } from "@mui/material";
import img from "../../assets/small_Icon.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";

const Section3 = () => {
  const { lg, md, sm } = useBreakpoints();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "5rem 0rem",
      }}
    >
      <Typography
        gutterBottom
        fontWeight={700}
        variant="body2"
        fontSize={md ? "45px" : sm ? "35px" : "30px"}
      >
        Get a Consultation
      </Typography>

      <ButtonBase
        sx={{
          backgroundColor: "#D1B06B",
          "&:hover": { backgroundColor: "#D1B06B" },
          mt: "1rem",
          padding: "1rem 2rem",
        }}
        onClick={() => navigate("/enquiry")}
      >
        <Typography
          variant="subtitle2"
          fontSize={sm ? "22px" : "18px"}
          fontWeight={500}
        >
          Contact US
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default Section3;
