import React, { useRef } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import img from "../../assets/team1.jpg";
import icon from "../../assets/small_Icon.svg";
import oval from "../consultancy/Oval.svg";
import dotted from "../consultancy/Dotted Shape.svg";
import useBreakpoints from "../../hooks/useBreakpoints";

import emailjs from "@emailjs/browser";

const Section1 = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { sm, md } = useBreakpoints();

  const form = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s5ue5qj",
        "template_gk4x8x5",
        form.current,
        "WeDMlyglrk-yBvjt5"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Form Submitted Succesfully!!");
          enqueueSnackbar("Form Submitted SuccessFully!!", {
            autoHideDuration: 3000,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          display: "flex",
        }}
      >
        <img src={img} alt={img} style={{ width: "100%" }} />
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "50%",
            margin: "auto",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "white",
              fontSize: md ? "65px" : sm ? "50px" : "30px",
              fontWeight: 700,
            }}
          >
            Consultancy
          </Typography>
          <img src={icon} alt={icon} style={{ width: "4.5rem" }} />
        </Box>
      </Box>

      <Box
        sx={{
          width: md ? "80%" : "95%",
          margin: "auto",
          marginTop: "5rem",
          marginBottom: "4rem",
          display: "flex",
          flexDirection: md ? "row" : "column",
          gap: "4rem",
        }}
      >
        <Box sx={{ minWidth: "40%" }}>
          <Typography sx={{ color: "#D0A144", fontSize: "0.8rem" }}>
            Book
          </Typography>
          <Typography
            sx={{ color: "#212B36", fontSize: "2.2rem", fontWeight: "900" }}
            variant="body2"
          >
            Get Online Consultancy
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            margin: "auto",
            position: "relative",
            display: "flex",
            ml: "3.5rem",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              top: -38,
              right: 42,
              flexDirection: "column",
              zIndex: -1,
              justifyContent: "flex-end",
            }}
          >
            <img
              src={oval}
              alt="oval"
              style={{ width: "100px", height: "100px" }}
            />
            <img
              src={dotted}
              alt="dotted"
              style={{ width: "100px", height: "100px" }}
            />{" "}
          </Box>
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              bottom: -20,
              left: -30,
              flexDirection: "column",
              zIndex: -1,
              justifyContent: "flex-end",
            }}
          >
            <img
              src={dotted}
              alt="dotted"
              style={{ width: "100px", height: "100px" }}
            />{" "}
          </Box>
          <Card sx={{ width: "80%", maxWidth: 500 }}>
            <CardContent>
              <form ref={form} onSubmit={sendEmail}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <TextField
                    label="Your Name"
                    name="name"
                    // color="#1F2732"
                    sx={{ color: "#1F2732" }}
                    variant="outlined"
                  />
                  <TextField
                    label="Your Email"
                    name="email"
                    variant="outlined"
                  />
                  <TextField
                    label="Your Phone"
                    name="phone"
                    variant="outlined"
                  />
                  <TextField
                    label="Your Message"
                    variant="outlined"
                    name="message"
                    multiline
                    rows={4}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#1F2732",
                      color: "white",
                      "&:hover": { backgroundColor: "#1F2732" },
                    }}
                  >
                    Book A Call
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Section1;
